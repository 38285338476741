import './style.scss';
import SeguradoraFormScreen from "../SeguradoraFormScreen";

const SeguradoraScreen = (props: any) => {

    return (
        <SeguradoraFormScreen {...props} />
    )
}

export default SeguradoraScreen