import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import './App.scss'
import { LoginScreen, LoadingScreen, PageNotFoundScreen } from './screens'
import { AuthProvider, LoadingProvider } from './providers/'
import Routes from "./config/routes";

function App() {
  const { user } = AuthProvider.useAuth();
  const { loading } = LoadingProvider.useLoading()

  if (loading) {
    return <LoadingScreen />
  }

  if (!user) {
    return (
      <Switch>
        <Route path="/">
          <LoginScreen />
        </Route>
      </Switch>
    )
  }
  
  return (
    <div className="page">
      <Switch>
      <Route exact path="/">
          <Redirect to={ Routes.FormularioSeguradoraAdd.path } />
        </Route>
          {
            Object.values(Routes).map((route: any, i: number) => 
            <Route 
              exact 
              key={i} 
              path={route.path} 
              render={ 
                (props: RouteComponentProps<any>) => {
                  if (route.path && route.component) {
                    const Screen = route.component as React.ComponentType<any>;
                    return <Screen route={route} {...props} />
                  }
                } 
              } 
            />
          )
        }
        <Route path="*">
          <PageNotFoundScreen />
        </Route>
      </Switch>
    </div>
  );
  
}

export default App;
