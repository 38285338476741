import { Card, Input, Select, Checkbox, DatePicker } from '../../components'
import '../../assets/scss/_button.scss'
import { Row, Col } from 'react-bootstrap'
import LMDatePicker from '../../components/DatePicker'
import { SelectFormatData } from '../../components/Select'
import { useEffect, useState } from 'react'
import Config from '../../config/config'
import {
    EstadoClient,
    CidadeClient,
} from '../../services/localidade'
import { AuthProvider } from '../../providers'

interface SelectOption {
    value: number;
    label: string;
    raw?: object;
}

interface Props {
    values: any
    handleChange: any
    touched: any
    errors: any
    setFieldValue: any
    setFieldTouched: any
}

const CadastroBasico: React.FC<Props> = ({ 
    values, 
    handleChange, 
    touched, 
    errors, 
    setFieldValue, 
    setFieldTouched 
}) => {
    const { user } = AuthProvider.useAuth();

    const [estadoAllList, setEstadoAllList] = useState<any[]>([])
    const [cidadeAllList, setCidadeAllList] = useState<any[]>([])

    const EstadoService = new EstadoClient(Config.API_BASE_URL).setBearer(String(user?.token));
    const CidadeService = new CidadeClient(Config.API_BASE_URL).setBearer(String(user?.token));

    useEffect(() => {
        EstadoService
            .populaAll(null)
            .then((res) => {
                if (!res.itens) return;
                
                setEstadoAllList(res.itens)
            })
            .catch(res => console.log(res))
    }, [])

    const handleSelectOption = (item: any, idSelect: string, nomeSelect: string, setFieldValue: any) => {
        setFieldValue(idSelect, item.value);
        setFieldValue(nomeSelect, { 'value': item.value ?? 0, 'label': item.label } as SelectOption);
    }

    const handleEstado = (a: any, item: any, idSelect: string, nomeSelect: string, setFieldValue: any) => {
        handleSelectOption(item, idSelect, nomeSelect, setFieldValue); 
        setFieldValue('cidadeId', null);
        setFieldValue('cidade', null);

        if(item.raw.id) {
            CidadeService
            .popula(item.raw.id ?? 0, null)
            .then((res) => {
                if (!res.itens) return;
                setCidadeAllList(res.itens)
            })
            .catch(res => console.log(res))
        }
    }

    return (
        <>
            <Card className="p-4 p-4-top15">
                <div className="grid-filter__header">
                    <h1 className="lm-title lm-title-100">
                        <Col xs={8}>
                            <span className="material-icons" >content_paste</span>
                            Cadastro Básico
                        </Col>
                    </h1>
                </div>

                <Row>
                    <Col>
                        <Select
                                name="estado"
                                label="Estado"
                                isMulti={false}
                                options={SelectFormatData(estadoAllList, 'id', 'sigla')}
                                onChange={(e: any) => { handleEstado(values, e, 'estadoId', 'estado', setFieldValue); }}
                                value={values.estado}                            
                                isInvalid={touched.estado && !!errors.estado}
                                errorMessage={errors.estado}
                            />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Select  
                            name="cidade" 
                            label="Cidade" 
                            isMulti={false}
                            value={ values.cidade } 
                            options={SelectFormatData(cidadeAllList, 'id', 'nome')} 
                            onChange={ 
                                (e: any) => { 
                                    handleSelectOption(e, 'cidadeId', 'cidade', setFieldValue)
                                }
                            } 
                            isInvalid={touched.cidade && !!errors.cidade} 
                            errorMessage={errors.cidade}
                        />
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <Input
                            name="placaLM"
                            label="Placa LM"
                            size="lg"
                            value={values.placaLM}
                            onChange={ handleChange }
                            isInvalid={touched.placaLM && !!errors.placaLM}
                            errorMessage={errors.placaLM}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Input
                            name="nomeTerceiro"
                            label="Nome Terceiro"
                            size="lg"
                            value={values.nomeTerceiro}
                            onChange={ handleChange }
                            isInvalid={touched.nomeTerceiro && !!errors.nomeTerceiro}
                            errorMessage={errors.nomeTerceiro}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Input
                            name="telefoneTerceiro"
                            label="Telefone Terceiro"
                            mask="(11)111111111"
                            size="lg"
                            value={values.telefoneTerceiro}
                            onChange={ handleChange }
                            isInvalid={touched.telefoneTerceiro && !!errors.telefoneTerceiro}
                            errorMessage={errors.telefoneTerceiro}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Input
                            name="emailTerceiro"
                            label="E-mail Terceiro"
                            size="lg"
                            value={values.emailTerceiro}
                            onChange={ handleChange }
                            isInvalid={touched.emailTerceiro && !!errors.emailTerceiro}
                            errorMessage={errors.emailTerceiro}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input
                            name="placaTerceiro"
                            label="Placa Terceiro"
                            size="lg"
                            value={values.placaTerceiro}
                            onChange={ handleChange }
                            isInvalid={touched.placaTerceiro && !!errors.placaTerceiro}
                            errorMessage={errors.placaTerceiro}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <div className="ocorrencia-search__form__date">
                            <span className="ocorrencia-search__form__date__label">Possuí seguro (Terceiro)</span>
                            <Checkbox
                                id="sim"
                                name="possui_seguro_check"
                                type="radio"
                                label="Sim"
                                checked={ values.possuiSeguro  }
                                onChange={ (e)=> setFieldValue('possuiSeguro', true) }
                            />
                            <Checkbox
                                id="nao"
                                name="possui_seguro_check"
                                type="radio"
                                label="Não"
                                checked={ !values.possuiSeguro  }
                                onChange={(e)=> setFieldValue('possuiSeguro', false)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input       
                            name="nomeSeguradora"
                            label="Seguradora Terceiro"
                            size="lg"
                            value={values.nomeSeguradora}
                            onChange={ handleChange }
                            isInvalid={touched.nomeSeguradora && !!errors.nomeSeguradora}
                            errorMessage={errors.nomeSeguradora}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input       
                            name="numProtocoloSeguradora"
                            label="Protocolo Seguradora Terceiro"
                            size="lg"
                            value={values.numProtocoloSeguradora}
                            onChange={ handleChange }
                            isInvalid={touched.numProtocoloSeguradora && !!errors.numProtocoloSeguradora}
                            errorMessage={errors.numProtocoloSeguradora}
                        />
                    </Col>
                </Row>            
                <Row>
                    <Col>
                        <DatePicker
                            name="dataAcidente"
                            label="Data do Acidente"
                            icon="event"
                            selected={ values.dataAcidente }
                            onChange={ (date: Date | null) => setFieldValue('dataAcidente', date) }
                            onChangeRaw={e => {
                                setFieldTouched('dataAcidente', true, true);
                            }}
                            size="lg"
                            mode="datetime"
                            isInvalid={touched.dataAcidente && !!errors.dataAcidente} 
                            errorMessage={errors.dataAcidente}
                            />

                        {/* <LMDatePicker
                            name="dataSinistro"
                            label="Data Sinistro"
                            icon="event"
                            selected={values.dataSinistro}
                            onChange={ (date: Date | null) => setFieldValue('dataSinistro', date) }
                            onChangeRaw={e => {
                                setFieldTouched('dataSinistro', true, true);
                            }}
                            errorMessage={errors.dataSinistro}
                            isInvalid={touched.dataSinistro && !!errors.dataSinistro} 
                            size="lg"
                            autoComplete="off"
                        /> */}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Input
                            as="textarea"
                            name="descricaoSinistro"
                            label="Descrição Sinistro"
                            value={values.descricaoSinistro}
                            onChange={handleChange}
                            isInvalid={touched.descricaoSinistro &&  !!errors.descricaoSinistro}
                            errorMessage={errors.descricaoSinistro}
                        />
                    </Col>
                </Row>
            </Card>
        </>        
    )
}

export default CadastroBasico