import { useState, useEffect, useRef } from 'react'
import { Form, Col, Row, Container } from 'react-bootstrap'
import Select, { components } from 'react-select'
import Icon from '@material-ui/core/Icon';
import { 
    Table, 
    CheckInput, 
    Modal,
    Button,
    Input,
    Checkbox,
    ManyToMany,
    Select as SelectExternal,
    CustomRadio,
    GridFilter
} from '../'
import Messages from '../../config/messages'
import './style.scss'

interface SelectOptionRaw {
    [key: string]: any
}

interface SelectOption {
    value: string | number;
    label: string | number;
    raw?: SelectOptionRaw;
}

interface ModalFieldsItem {
    name: string,
    label: string,
    multipleNames?: string[],
    value?: any,
    type?: string,
    options?: SelectOption[],
    hidden?: boolean,
    default?: boolean,
    readonly?: boolean,
    mask?: string,
    col?: number
    isMulti?: boolean,
    inputSuffix?: string
}

interface ModalProps {
    title: string,
    subtitle?: string,
    label?: string,
    tip?: string,
    enableMap?: boolean,
    fields?: ModalFieldsItem[],
    advancedFields?: ModalFieldsItem[],
    resultsMode?: 'table' | 'many-to-many'
}

interface Props {
    label: string,
    name: string,
    options?: any,
    value?: string | number | SelectOption | SelectOption[],
    modal?: ModalProps,
    isMulti?: boolean,
    isInvalid?: boolean,
    errorMessage?: string | string[],
    fetching?: boolean,
    openModalOnFocus?: boolean,
    icon?: string,
    textoConfirmar?: string,
    textoCancelar?: string,
    isDisabled?: boolean,
    disabled?: boolean,
    onChange?(event: any): void,
    onInputChange?(event: any): void,
    disableOptionsCompression?: boolean
}

const SelectFormatData = (list: any[], value: string, label: string) : SelectOption[] | undefined => {
    return list?.map((item : any) => (
        { 'value': item[value], 'label': item[label], 'raw': item  } as SelectOption
    ))
}

const IndicatorSeparator = () => <></>;

const LMSelect = (props: Props) => {

    const {
        label,
        name,
        isInvalid,
        errorMessage,
        onChange,
        modal,
        value,
        isMulti,
        onInputChange,
        options,
        fetching = false,
        openModalOnFocus = false,
        icon = "expand_more",
        textoConfirmar = Messages.inputtag.modal.confirm,
        textoCancelar = Messages.inputtag.modal.cancel,
        disabled = false,
        disableOptionsCompression = false,
        ...selectProps
    } = props;

    const DropdownIndicator = (props: any) => {
        return (
          <components.DropdownIndicator {...props}>
            <Icon>{icon}</Icon>
          </components.DropdownIndicator>
        );
    };
    
    const [tags, setTags] = useState<any[]>([]);
    const [modalTags, setModalTags] = useState<any[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false)
    const [dataWidth, setDataWidth] = useState<number>(0)
    const [fieldWidth, setFieldWidth] = useState<number>(0)
    const [modalExitTime, setModalExitTime] = useState<Date>(new Date())
    const [fieldsData, setFieldsData] = useState<any>({})
    const [advancedFilterVisible, SetAdvancedFilterVisible] = useState<boolean>(true)

    const fieldRef = useRef<HTMLDivElement>(null)

    const className = isInvalid ? 'is-invalid' : ''

    const inputClassName = "lm-form-select__control form-control-lg";

    const modalFields = (modal && modal.fields) ? modal.fields.map((item: any) => item.value) : []

    useEffect(() => {
        if (!value) { 
            setTags([null])
            setModalTags([null])
            return;
        };
        setTags(value instanceof Array ? [...value] : [value])
        
        if (modal) {
            setModalTags(value instanceof Array ? [...value] : [value])
        }
    }, [value])

    useEffect(() => {
        if (modal && modal.fields) {
            const modalFieldsValue: any = {}
            
            modal.fields.map((item: any) => modalFieldsValue[item.name] = item.value ? item.value : '')
            modal.advancedFields?.map((item: any) => modalFieldsValue[item.name] = item.value ? item.value : '')

            setFieldsData(modalFieldsValue)
        }

    }, modalFields)

    const calcModal = (labels: string[]) => {
        if (!fieldRef || !fieldRef.current) return;

        setFieldWidth(fieldRef?.current.offsetWidth)
        setDataWidth(labels.length > 0 ? (labels.join('').length * 7 + labels.length * 42 + 115) : 0)
    }

    const handleChange = (option: any) => {
        onChange && onChange(option)

        if (isMulti) {
            setTags(option)
            calcModal(option.map((item: SelectOption) => item.label))
        }
    }

    const handleModalChange = (tags: any[]) => {
        setModalTags(tags)
    }

    const handleModalInput = (value: string | null, field: ModalFieldsItem) => {
        if (!field) return;
        
        let fields = {
            ...fieldsData
        }
        
        fields[field.name] = value
        setFieldsData(fields)

        onInputChange && onInputChange(fields)
    }

    const handleModalCheckbox = (value: boolean, field: ModalFieldsItem) => {
        let fields = {
            ...fieldsData
        }
        fields[field.name] = value
        setFieldsData(fields)
        onInputChange && onInputChange(fields)
    }

    const handleModalSelect = (data: SelectOption, field: ModalFieldsItem) => {
        if (!data || !field) return;
        
        const fields = {
            ...fieldsData
        }

        fields[field.name] = data
        setFieldsData(fields)

        onInputChange && onInputChange(fields)
    }

    const handleModalCancel = () => {
        setShowModal(false)
        setModalExitTime(new Date())
        setModalTags([value])
    }

    const handleModalConfirm = () => {
        setTags(modalTags)
        handleChange(modalTags)
        setModalExitTime(new Date())
        setShowModal(false)

        calcModal(modalTags.map((item: SelectOption) => item.label.toString()))
    }

    const handleModalOpen = () => {
        setModalTags(tags)
        setShowModal(true)
    }

    const handleInputChange = (data: any) => {
        if (modal && modal.fields) {
            const defaultField = modal.fields.find((item: any) => item.default)

            const values = { ...fieldsData }
            values[defaultField ? defaultField.name : modal.fields[0].name] = data && data.target.value;
            setFieldsData(values)
        }
    }

    const handleInputSubmit = (event?: any) => {
        if (!modal || !modal.fields) return;

        setShowModal(true)
    }

    const handleInputFocus = (event: any) => {
        const dateDiff = Math.abs(modalExitTime?.getTime() - new Date().getTime())
        if (dateDiff > 500 && openModalOnFocus) {
            handleInputSubmit()
        }
    }

    const renderModalInput = (data: ModalFieldsItem) => {

        let component;

        switch (data.type) {
            case 'select':
                component = (
                    <SelectExternal
                        name={data.name}
                        label={data.label}
                        options={
                            [
                                {
                                    "label":  "Selecione",
                                    "value": ""
                                },
                                ...data.options ?? []
                            ]
                        }
                        onChange={ (value: SelectOption) => handleModalSelect(value, data) }
                        value={ data.value ? data.value : fieldsData[data.name] }
                    />
                )
                break;
            case 'custom-radio':
                component = (
                    <CustomRadio
                        label={data.label}
                        options={ data.options }
                        onChange={ (value: SelectOption) => handleModalSelect(value, data) }
                        value={ data.value }
                    />
                )
                break;
            case 'radio':
                component = (
                    <Checkbox
                        id={data.name}
                        name={data.name}
                        type="radio"
                        label={data.label}
                        onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleModalInput(event.target.value, data) }
                    />
                )
                break;
            case 'checkbox':
                component = (
                    <Checkbox
                        id={data.name}
                        name={data.name}
                        type="checkbox"
                        label={data.label}
                        onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleModalCheckbox(event.target.checked, data) }
                    />
                )
                break;
            case 'checkbox-input':
                component = (
                    <CheckInput
                        id={data.name}
                        name={data.name}
                        type="checkbox"
                        label={data.label}
                        onChange={ (value: any) => handleModalInput(value, data) }
                        inputSuffix={data.inputSuffix}
                    />
                )
                break;
            default:
                component = (
                    <Input 
                        name={ data.name }
                        label={ data.label }
                        onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleModalInput(event.target.value, data) }
                        value={ data.value ? data.value : fieldsData[data.name] }
                        size="lg"
                        readOnly={ data.readonly }
                        mask={data.mask}
                    />
                )
                break;
        }

        return component;
    }

    const handleTableItemClick = (data: any, isActive: boolean) => {
        let tagsCopy = [...modalTags];

        if (isMulti) {
            if (isActive) {
                tagsCopy = tagsCopy.filter(item => item.value !== data.row.original.id)
            } else {
                tagsCopy = [
                    ...tagsCopy,
                    { 'value': data.row.original.id, 'label': data.row.original.fornecedor, 'raw': data.row.original  }
                ]
            }
        } else {
            if (isActive) {
                tagsCopy = []
            } else {
                tagsCopy = [
                    { 'value': data.row.original.id, 'label': data.row.original.fornecedor, 'raw': data.row.original  }
                ]
            }
        }

        setModalTags(tagsCopy)
    }
    
    return (
        <Form.Group controlId={ name } className="lm-form-select" ref={fieldRef}>
            { (modal && (dataWidth > fieldWidth && modal.fields && tags && tags.length > 1 ) || tags.length > 1) && !disableOptionsCompression && (
                <div className={ `${inputClassName} lm-form-select__many` }>
                    <div className="lm-form-select__many__label">
                        { tags.length} selecionados
                    </div>
                    <Button variant="link" className="lm-form-select__many__btn" onClick={ handleModalOpen }>
                        editar
                    </Button>
                </div>
            )}
            { modal && modal.fields && !tags && (
                <Input 
                    name={ name }
                    label={ label }
                    onChange={ handleInputChange }
                    onKeyPress={ (event: any) => event.key === 'Enter' && handleInputSubmit() }
                    onIconClick={ handleInputSubmit }
                    size="lg"
                    icon={icon}
                    onFocus={ handleInputFocus }
                />
            )}
            { (tags.length < 2 || disableOptionsCompression) && (
                <Select 
                    className={className} 
                    classNamePrefix="lm-form-select" 
                    placeholder="Selecione" 
                    noOptionsMessage={() => modal ? null : Messages.select.noOptions}
                    components={{ DropdownIndicator, IndicatorSeparator }}
                    onChange={ handleChange }
                    value={ isMulti ? tags : tags[0] }
                    isDisabled={disabled}
                    isMulti={isMulti}
                    onInputChange={ (modal && modal.fields) ? handleInputChange : onInputChange }
                    options={ modal && modal.fields ? [] : options }
                    onFocus={ handleInputFocus }
                    {...selectProps}
                />
            )}
            <Form.Label>{ label }</Form.Label>
            { errorMessage && (
                <Form.Control.Feedback type="invalid">
                    {errorMessage}
                </Form.Control.Feedback>
            )}
            { modal && !modal.fields && (
            <Modal show={showModal} onHide={handleModalCancel} className="lm-form-select__modal lm-form-select__modal--single-field" centered>
                <Modal.Header>
                    { modal.subtitle && (
                    <div className="lm-form-select__modal__subtitle">
                        { modal.subtitle }
                    </div>
                    )}
                    { modal.title && (
                    <div className="lm-form-select__modal__title">
                        { modal.title }
                    </div>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>{ modal.label }</Form.Label>
                    <Select 
                        className={className} 
                        classNamePrefix="lm-form-select" 
                        placeholder="Selecione" 
                        noOptionsMessage={() => Messages.select.noOptions}
                        components={{ DropdownIndicator, IndicatorSeparator }}
                        onChange={ handleModalChange }
                        value={ modalTags }
                        isMulti={isMulti}
                        options={options}
                        {...selectProps}
                    />
                    <Button className="lm-form-select__modal__btn-clear" variant="link" onClick={ () => setModalTags([]) }>
                        {Messages.inputtag.modal.clear}
                    </Button>
                    { modal && modal.tip && (
                        <div className="lm-form-select__modal__tip">
                            <span>{Messages.inputtag.modal.tip}</span> { modal.tip }
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button block size="lg" variant="outline-primary" className="lm-form-select__modal__btn-cancel" onClick={handleModalCancel}>
                        {Messages.inputtag.modal.back}
                    </Button>
                    <Button block size="lg" variant="primary" className="lm-form-select__modal__btn-confirm" onClick={handleModalConfirm}>
                        {Messages.inputtag.modal.confirm}
                    </Button>
                </Modal.Footer>
            </Modal>
            )}
            { modal && modal.fields && (
            <Modal show={showModal} onHide={handleModalCancel} className="lm-form-select__modal lm-form-select__modal--multiple-fields" centered>
                <Modal.Header>
                    { modal?.subtitle && (
                    <div className="lm-form-select__modal__subtitle">
                        { modal.subtitle }
                    </div>
                    )}
                    { modal?.title && (
                    <div className="lm-form-select__modal__title">
                        { modal.title }
                    </div>
                    )}
                </Modal.Header>
                <Modal.Body>
                    { modal?.fields && (
                        <>
                            <div className="lm-form-select__modal__fields">
                                <Container>
                                    <Row>
                                    { 
                                        modal.fields.map((item: ModalFieldsItem, index: number) => {
                                            return (
                                                <Col className="lm-form-select__modal__fields__col" xs={ item.col } key={ index }>
                                                    { renderModalInput(item) }
                                                </Col>
                                            )
                                        }) 
                                    }
                                    </Row>
                                </Container>
                            </div>
                            { modal.advancedFields && (
                                <GridFilter title="Busca Avançada" visible={advancedFilterVisible} setVisible={SetAdvancedFilterVisible}>
                                    <Container>
                                        <Row>
                                        { 
                                            modal.advancedFields.map((item: ModalFieldsItem, index: number) => {
                                                return (
                                                    <Col className="lm-form-select__modal__fields__col" xs={ item.col } key={ index }>
                                                        { renderModalInput(item) }
                                                    </Col>
                                                )
                                            }) 
                                        }
                                        </Row>
                                    </Container>
                                </GridFilter>
                            )}
                            {
                                modal.resultsMode === 'table' ? (
                                    <Table 
                                        data={
                                            options.length ? options.map((item: any) => item.raw) : []
                                        }
                                        columns={
                                            modal.fields
                                            .filter((item: ModalFieldsItem) => !item.hidden)
                                            .map(item => (
                                                {
                                                    Header: item.label,
                                                    accessor: (row: any) =>  row,
                                                    Cell: ({ value }: any) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    item.multipleNames ? 
                                                                    item.multipleNames.map(item => <div> { value[item] } </div>) :
                                                                    value[item.name]
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                }
                                            ))
                                        }
                                        className="lm-form-select__modal__table"
                                        enableColumnHide={false}
                                        enableExport={false}
                                        enablePrint={false}
                                        maxPageSize={200}
                                        customActionsColumn={
                                            (cell: any) => {
                                                if (!cell || !cell.row) return;
                                                const isActive = modalTags.filter(item => item.value === cell.row.original.id).length > 0;
                                                return (
                                                    <Button variant={ isActive ? "primary" : "outline-primary" } className={ `lm-form-select__modal__table__btn lm-form-select__modal__table__btn-select ${ isActive ? 'lm-form-select__modal__table__btn-select--active' : ''}` } onClick={ () => handleTableItemClick(cell, isActive) }>
                                                        <Icon>
                                                            { isActive ? 'check' : 'radio_button_unchecked'  }
                                                        </Icon>
                                                        Selecionar
                                                    </Button>
                                                )
                                            }
                                        }
                                />
                            ) : (
                                <ManyToMany 
                                    columns={ modal.fields.filter((item: ModalFieldsItem) => !item.hidden) }
                                    options={options}
                                    value={modalTags}
                                    onChange={ handleModalChange }
                                    fetching={fetching}
                                    isMulti={isMulti}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button block size="lg" variant="outline-primary" className="lm-form-select__modal__btn-cancel" onClick={handleModalCancel}>
                        {textoCancelar}
                    </Button>
                    <Button block size="lg" variant="primary" className="lm-form-select__modal__btn-confirm" onClick={handleModalConfirm}>
                        {textoConfirmar}
                    </Button>
                </Modal.Footer>
            </Modal>
            )}
        </Form.Group>
    )
}

export default LMSelect
export { SelectFormatData }
export type { SelectOption }