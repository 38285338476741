import { enc, AES, mode, pad } from "crypto-js"

const encrypt = (data: any) => {
    let text = JSON.stringify(data);

    let bytes = enc.Utf8.parse(text);

    let key = enc.Utf8.parse('1953232066935671');

    let iv = enc.Utf8.parse('3409945788197148');

    let encrypted = AES.encrypt(bytes,
        key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: mode.CBC,
            padding: pad.Pkcs7
        });

    return JSON.stringify(encrypted.toString());
}

export default encrypt