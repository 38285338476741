const messages = {
    'form': {
        'required': 'Este campo é obrigatório.',
        'min': 'É obrigatório selecionar pelo menos um item.',
        'saveSuccess': 'Informações salvas com sucesso.',
        'saveError': 'Não conseguimos completar sua solicitação.',
        'maxLength250': 'Este campo só aceita no máximo 250 caracteres.',
        'dateEnd': `Este campo precisa ser maior que data de início.`,
        'numInvalido': `Número inválido.`,
        'numberRequired': 'Deve ser um número.',
        'numberPositive': 'Deve ser um número positivo.',
        'emailRequired': 'E-mail inválido',
        'sendEmailSuccess': 'E-mail enviado com sucesso.'
    },
    'table': {
        'add': 'Adicionar',
        'results': 'Resultados',
        'showColumns': 'Exibir Colunas',
        'print': 'Imprimir',
        'export': 'Exportar',
        'cancelarConfirm': 'Deseja cancelar esse item?',
        'deleteConfirm': 'Deseja desativar esse item?',
        'cancelarConfirmYes': 'Cancelar',
        'deleteConfirmYes': 'Desativar',
        'deleteConfirmNo': 'Voltar',
        'cancelarSucess': 'Item cancelado com sucesso',
        'deleteSucess': 'Item desativado com sucesso',
        'deleteError': 'Não é possível desativar este item.',
        'cancelSucess': 'Item cancelado com sucesso',
        'finishSucess': 'Item encerrado com sucesso',
        'cancelError': 'Não é possível cancelar este item.',
        'empty': 'Nenhum dado encontrado',
        'show': 'Mostrar',
        'hide': 'Ocultar'
    },
    'gridfilter': {
        'title': 'Buscar',
        'showFields': 'Mostrar campos',
        'hideFields': 'Ocultar campos',
    },
    'select': {
        'noOptions': 'Nenhum dado encontrado'
    },
    'inputtag': {
        'modal': {
            'cancel': 'Cancelar',
            'confirm': 'Confirmar',
            'clear': 'Limpar',
            'tip': 'Dica:',
            'back': 'Voltar'
        }
    },
    'action': {
        'cancel': 'Cancelar',
        'preview': 'Visualizar',
        'add': 'Adicionar',
        'new': 'Novo',
        'finish': 'Encerrar'
    }
}

export default messages