const regraespecial = {
    'OC005_VAL': {
        'sigla': 'OC005_VAL',
        'descricao': 'Usuários de perfis atribuídos a essa regra serão entendidos como validadores no módulo de validaçao.'
    },
    'OC005_SUP': {
        'sigla': 'OC005_SUP',
        'descricao': 'Usuários de perfis atribuídos a essa regra serão entendidos como supervisores no módulo de atribuição.'
    },
    'OC005_AGEN': {
        'sigla': 'OC005_AGEN',
        'descricao': 'Usuários de perfis atribuídos a essa regra serão entendidos como agendadores no módulo de atribuição.'
    },
    'SR007_SOL': {
        'sigla': 'SR007_SOL',
        'descricao': 'Usuários de perfis atribuídos a essa regra serão entendidos como solicitantes da reserva no módulo de solicitação de reserva.'
    },
    'SR007_ADM': {
        'sigla': 'SR007_ADM',
        'descricao': 'Usuários de perfis atribuídos a essa regra serão entendidos como administradores da reserva no módulo de solicitação de reserva.'
    },
    'OC001_SEG': {
        'sigla': 'OC001_SEG',
        'descricao': 'Usuários de perfis atribuídos a essa regra serão entendidos como usuários do formulário seguradora'
    }
}

export default regraespecial