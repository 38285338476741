import { AuthProvider } from '../../providers'
import { Content } from '../../components'
import './style.scss'

const PageNotFoundScreen = () => {
    
    const { user, signout } = AuthProvider.useAuth();

    return (
         <Content user={ user?.name } signout={ signout }>
            <div className="page-not-found__text">
                    Página não encontrada
            </div>
        </Content>
    )
}

export default PageNotFoundScreen