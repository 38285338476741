import React, { useState, useContext, createContext } from "react";

type LoadingProviderType = {
    children: React.ReactNode
}

interface LoadingContextType {
  loading: boolean;
  setLoading(state: boolean): void;
}

const LoadingContext = createContext<LoadingContextType>({} as LoadingContextType);

function Provider({ children } : LoadingProviderType ) {
  const loading = useProvideLoading();
  return <LoadingContext.Provider value={loading}>{children}</LoadingContext.Provider>;
}

const useLoading = () => {
  return useContext(LoadingContext);
};

function useProvideLoading() {

  const [loading, setLoading] = useState<boolean>(true);

  return {
    loading,
    setLoading
  };
}

const AuthProvider = {
  useLoading,
  Provider
}

export default AuthProvider