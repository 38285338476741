import React from 'react'
import { Dropdown, ButtonGroup } from 'react-bootstrap'
import './style.scss'

interface OptionItem {
    label: string | number | React.ReactElement,
    key: string
}
interface DropdownProps {
    id: string,
    label: string | number,
    options: OptionItem[],
    onSelect(eventKey: string | null, e: React.SyntheticEvent<unknown>): void
}

const LMDropdown: React.FC<DropdownProps> = (props) => {

    const { id, label, options, onSelect, } = props;

    return (
        <Dropdown as={ButtonGroup} className="lm-dropdown" id={id}>
            <Dropdown.Toggle>
                { label }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                { options.map((item: OptionItem, index: number) => 
                    <Dropdown.Item onSelect={ onSelect } eventKey={item.key} key={index}>{item.label}</Dropdown.Item>
                ) }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LMDropdown