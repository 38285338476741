import React, { useEffect, useMemo, useState } from 'react'
import { useTable, usePagination, useSortBy, Column } from 'react-table'
import { Dropdown, DropdownCheckbox, Button, Spinner, Modal } from '../'
import Message from '../../config/messages'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from 'react-tooltip';
import './style.scss'

export type Cell = {
    render: (type: string) => any;
    getCellProps: () => any;
    column: Column;
    row: Row;
    state: any;
    value: any;
};

export type Row = {
    index: number;
    cells: Cell[];
    getRowProps: () => any;
    originalRow: any;
};

export interface ColumnItem {
    Header?: string | ((props: any) => JSX.Element | string);
    accessor: string | ((originalRow: any) => string);
    id?: string | number;
    Cell?: string | ((cell: Cell) => JSX.Element | string);
    hidden?: boolean
}

export interface Access {
    create?: boolean;
    read?: boolean;
    update?: boolean;
    delete?: boolean;
}

export interface TableProps {
    title?: string,
    data: Array<Object>,
    columns: Array<ColumnItem>,
    idField?: string,
    className?: string,
    totalPages?: number,
    initialPageSize?: number,
    maxPageSize?: number,
    pageClick?(page: Object): void,
    changePageSize?(pageSize: number): void,
    handleDelete?(data: Object): void | Promise<any>,
    handleEdit?(data: Object): void | Promise<any>,
    handleView?(data: Object): void | Promise<any>,
    handleCancel?(data: Object): void | Promise<any>,
    handleAdd?(data: Object): void | Promise<any>,
    enableColumnHide?: boolean,
    enableExport?: boolean,
    enablePrint?: boolean,
    enablePagination?: boolean,
    enableRowTooltip?: boolean,
    loading?: boolean,
    footer?: React.ReactNode,
    idFieldDisableDelete?: string,
    deleteIconClose?: boolean,
    mensagemModalDesativar?: string,
    mensagemModalDesativarBtnCancelar?: string,
    mensagemModalDesativarBtnOk?: string,
    mensagemModalConfirmacao?: string,
    access?: Access,
    customActionsColumn?(cell: Object): React.ReactNode | null,
    customActionsHeader?(): React.ReactNode | null
}

const Table: React.FC<TableProps> = (props: TableProps) => {

    const { 
        title,
        data,
        columns, 
        pageClick, 
        totalPages, 
        initialPageSize = 5,
        maxPageSize = 20,
        changePageSize,
        handleAdd,
        handleEdit,
        handleView,
        handleDelete,
        footer,
        enableColumnHide = true,
        enableExport = true,
        enablePrint = true,
        enablePagination = true,
        enableRowTooltip = false,
        loading = true,
        idField = 'id',
        deleteIconClose = false,
        mensagemModalDesativar = Message.table.deleteConfirm,
        mensagemModalDesativarBtnCancelar = Message.table.deleteConfirmNo,
        mensagemModalDesativarBtnOk = Message.table.deleteConfirmYes,
        mensagemModalConfirmacao = Message.table.deleteSucess,
        access,
        className,
        customActionsColumn,
        customActionsHeader
    } = props;

    const history = useHistory();

    const [pageSizeList, setPageSizeList] = useState<number[]>(Array.from(Array(5).keys()).map(i => i * initialPageSize));
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [rowDeleteData, setRowDeleteData] = useState();

    const actionHeader = () => customActionsHeader ? customActionsHeader() : (
        <>
            { handleAdd && (access && access.create) && (
                <Button variant="link" className="lm-table__header__btn-add float-right" onClick={handleAdd}>                        
                    <AddIcon />
                </Button>
            )}
        </>
    )

    const actionButtons = (cell: any) => customActionsColumn ? customActionsColumn(cell) : (
        <div className="lm-table__actions">
            { handleView && cell.row.original[idField] && ( !cell.row.original['audit'] || (cell.row.original['audit'] && cell.row.original['audit'].desativado) )  && (access && access.read) && (
            <Button onClick={ () => handleView(cell) } size="sm" variant="primary" className="mr-2 lm-table__actions__btn lm-table__actions__btn--view">
                <RemoveRedEyeIcon />
            </Button>
            )}
            { handleEdit && cell.row.original[idField] && ( !cell.row.original['audit'] || (cell.row.original['audit'] && !cell.row.original['audit'].desativado) ) && (access && access.update) && (
            <Button onClick={ () => handleEdit(cell) } size="sm" variant="primary" className="mr-2 lm-table__actions__btn lm-table__actions__btn--edit">
                <CreateIcon />
            </Button>
            )}
            { handleDelete && cell.row.original[idField] && (access && access.delete) && (
            <Button 
                onClick={ 
                    () => {
                        setRowDeleteData(cell.row)
                        setShowDeleteModal(true) 
                    }
                } 
                size="sm"
                variant="danger"
                className="lm-table__actions__btn lm-table__actions__btn--delete"
                disabled={cell.row.original['audit'] && cell.row.original['audit'].desativado}
            >
                { deleteIconClose && 
                    <CloseIcon />
                }
                { !deleteIconClose && 
                    <DeleteIcon />
                }
            </Button>
            )}
        </div>
    )
    
    const actionColumn = {
        accessor: 'actions',
        disableSortBy: true,
        Header: () => actionHeader(),
        Cell: ({cell} : any) => actionButtons(cell)
    } as Object;
    
    if (!columns.find((item) => item.accessor === "actions")) {
        columns.push(actionColumn as ColumnItem)
    }
    
    const [tableData, setTableData] = useState(data)

    let memoColumns = useMemo(() => columns as Column<Object>[], [columns])

    useEffect(() => {
        setTableData([...data])
        ReactTooltip.rebuild();
    }, [data])

    const exportOptions = [
        {
            label: 'PDF',
            key: 'pdf'
        },
        {
            label: 'XLS',
            key: 'xls'
        },
    ]

    const handleCloseDeleteModal = (ev: any) => {
        setShowDeleteModal(false)
    }

    const handleConfirmDeleteModal = (data: any) => {          
        handleDelete && Promise.resolve(handleDelete(data.original)).then(() => {
            setShowDeleteModal(false)
            const tableDataCopy = [...tableData]
            tableDataCopy.splice(data.index, 1)
            setTableData(tableDataCopy)
            toast.success(mensagemModalConfirmacao)
        })
        .catch((err) => {
            setShowDeleteModal(false)
            toast.error(Message.table.deleteError);
        })
    }
    
    const handleChangePageSize = (pageSize: any) => {
        
        const pageDiff = pageSizeList[1] - pageSizeList[0];
        if (pageSize == pageSizeList[pageSizeList.length-1] && pageSizeList[pageSizeList.length-1] < maxPageSize) {
            setPageSizeList([
                ...pageSizeList,
                ...Array.from(Array(5).keys()).map(i => (i+1+pageSizeList.length) * pageDiff)
            ])
        }

        if (changePageSize) {
            changePageSize(pageSize)
            setPageSize(pageSize)
        }
    }

    const handleExport = (type: string | null) => {
        console.log(type)
    }

    const handlePrint = () => {
        window.print()
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        setPageSize,
        allColumns,
        state: { pageSize }
    } = useTable({ 
        columns: memoColumns as Column<Object>[], 
        data: tableData,
        manualPagination: enablePagination,
        initialState: { 
            pageSize: initialPageSize,
            hiddenColumns: columns.filter((col: any) => col.hidden === true).map(col => col.id || col.accessor) as any
        }, 
        pageCount: totalPages
    }, useSortBy, usePagination)

    return (
        <>
            <div className="lm-table__options">
                { enableColumnHide && (
                    <div className="lm-table__options__item lm-table__options__item--custom-columns">
                        <DropdownCheckbox 
                            label={Message.table.showColumns} 
                            options={ 
                                allColumns.map((column: any) => (
                                    { 
                                        label: column.Header,
                                        key: column.id,
                                        checked: column.isVisible,
                                        toggleHidden: column.toggleHidden
                                    }
                                ))} 
                            id="custom-columns" 
                        />
                    </div>
                )}
                { enableExport && (
                <div className="lm-table__options__item lm-table__options__item--export">
                    <Dropdown 
                        label={Message.table.export}
                        options={ exportOptions } 
                        onSelect={ (eventKey) => handleExport(eventKey) } id="export" 
                    />
                </div>
                )}
                { enablePrint && (
                <div className="lm-table__options__item lm-table__options__item--print">
                    <Button onClick={handlePrint}>
                        {Message.table.print}
                    </Button>
                </div>
                )}
                
                { enablePagination && (
                <div className="lm-table__options__item lm-table__options__item--pagination">
                    <div className="lm-table__page-size">
                        <div className="lm-table__page-size__label">{Message.table.results}</div>
                        <Dropdown 
                            label={ pageSize } 
                            options={ pageSizeList.slice(1).map(item => ({ label: item, key: String(item) })) } 
                            onSelect={ (eventKey) => handleChangePageSize(eventKey) } id="page-size" 
                        />
                    </div>
                    <ReactPaginate
                        previousLabel={ <ChevronLeftIcon className="lm-table__paginate__arrow__label" /> }
                        nextLabel={ <ChevronRightIcon  className="lm-table__paginate__arrow__label" /> }
                        breakLabel={'...'}
                        breakClassName="lm-table__paginate__break"
                        pageClassName="lm-table__paginate__page"
                        nextClassName="lm-table__paginate__arrow"
                        previousClassName="lm-table__paginate__arrow"
                        containerClassName="lm-table__paginate"
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={pageClick}
                        activeClassName={'active'}
                    />
                </div>
                )}
                
            </div>
            <div className={`lm-table__container ${className ? className : ''}`}>
                <table {...getTableProps()} className="lm-table">
                    <thead className="lm-table__head">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render('Header')}
                                        { column.canSort && (
                                            <span className="lm-table__head__sort">
                                                {
                                                    column.isSorted ? (<UnfoldMoreIcon className="lm-table__head__sort__icon active" />) : (<UnfoldMoreIcon className="lm-table__head__sort__icon" />)
                                                }
                                            </span>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="lm-table__body">
                        { 
                            page.map((row: any) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} key={ 'row-'+row.original[idField] }>
                                    {row.cells.map((cell: any, index: number) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                key={ `col-${cell.row.original[idField]}-${index}`}
                                            >
                                                { cell.column.id !== "actions" ? (
                                                    <span 
                                                        data-tip={
                                                            (enableRowTooltip && cell.column.id !== "actions") ? JSON.stringify({
                                                                row: {
                                                                    original: cell.row.original
                                                                }
                                                            }) : false
                                                        } 
                                                        data-for={ (enableRowTooltip && cell.column.id !== "actions") && 'tableRowTip' }
                                                    >
                                                        {cell.render('Cell')}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {cell.render('Cell')}
                                                    </span>
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                    {
                        footer && (
                            <tfoot>
                                { footer }
                            </tfoot>
                        )
                    }
                </table>
            </div>
            { 
                page.length < 1 && loading && (
                    <div className="lm-table__loading">
                        <Spinner animation="border" variant="primary" />
                    </div>
                )
            }
            {
                page.length < 1 && !loading && (
                    <div className="lm-table__loading">
                        { Message.table.empty }
                    </div>
                )
            }
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} className="lm-table__modal lm-table__modal-delete" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{mensagemModalDesativar}</Modal.Body>
                <Modal.Footer>
                    <Button block size="lg" variant="outline-primary" onClick={handleCloseDeleteModal}>
                        {mensagemModalDesativarBtnCancelar}
                    </Button>
                    <Button block size="lg" variant="danger" onClick={() => handleConfirmDeleteModal(rowDeleteData)}>
                        {mensagemModalDesativarBtnOk}
                    </Button>
                </Modal.Footer>
            </Modal>
            { enableRowTooltip && (
                <ReactTooltip 
                    id="tableRowTip"
                    delayHide={500}
                    delayUpdate={500}
                    effect="solid" 
                    place="right" 
                    getContent={ cell => {
                        if (!cell) return;
                        return actionButtons(JSON.parse(cell))
                    }}
                />
            )}
        </>
    )
}

export default Table