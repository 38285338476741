import React, { useEffect, useRef } from 'react';
import FileViewer from 'react-file-viewer';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import ThumbnailPDF from '../../assets/thumbnail/thumbnail-pdf.png'
import ThumbnailDocx from '../../assets/thumbnail/thumbnail-docx.png'
import ThumbnailUnknown from '../../assets/thumbnail/thumbnail-unknown.png'
import './styles.scss'
import { Button } from '..';

type Mode = 'normal' | 'fullscreen' | string

interface Props {
    files: Array<ReactImageGalleryItem>
    mode?:  Mode
    show?: boolean
    onShow?: Function
    startIndex?: number
}

const defaultProps = {
    mode: 'normal',
    show: true,
    onShow: undefined,
    startIndex: 0
}

const FileGallery: React.FC<Props> = ({ files, mode, show, onShow, startIndex }) => {
    const ref = useRef<ImageGallery>(null);

    const getFileFormat = (url: string) => {
        return url.split('.').pop() || ''
    }

    const getThumbnail = (thumbnail: string | undefined, fileFormat: string | undefined) => {
        switch(fileFormat) {
            case 'pdf':
                return ThumbnailPDF
            case 'docx':
                return ThumbnailDocx
            case 'png':
            case 'jpg':
                return thumbnail
            default:
                return ThumbnailUnknown
        }
    }

    const handleDownloadFile = (url: string, name: string | undefined, fileFormat: string) => {
        const link = document.createElement('a')

        link.href = url;
        link.setAttribute("download", name || `file.${fileFormat}`);
        link.click()
        link.remove()
    }

    const handleScreenChange = (isFullscreen: boolean) => {
        onShow && onShow(isFullscreen)
    }

    const renderItem = (item: ReactImageGalleryItem) => {
        const fileFormat = getFileFormat(item.original)

        switch(fileFormat) {
            case 'png':
            case 'jpg':
                return (
                    <img
                        className='image-gallery-image'
                        src={item.original}
                        alt={item?.originalAlt}
                    />)
            case 'pdf':
                return (
                    <div className='image-gallery-pdf'>
                        <iframe
                            title="teste"
                            src={item.original}
                        />                    
                    </div>
                )
            case 'docx':
                return (
                    <FileViewer
                        fileType={fileFormat}
                        filePath={item.original}
                    />
                )
            default:
                return (
                    <div className='image-gallery-no-support'>
                        <p>Algo deu errado.</p>
                        <p>Não foi possível gerar uma visualização para este arquivo.</p>
                        <p>Tente baixar o arquivo para visualizá-lo.</p>
                        <Button 
                            variant="outline-primary" 
                            className="float-right" 
                            onClick={() => handleDownloadFile(item.original, item.originalTitle, fileFormat)}
                        >
                            Baixar
                        </Button>
                    </div>
                )
        }
    }

    const renderThumbInner = (item: ReactImageGalleryItem) => {
        const fileFormat = getFileFormat(item.original)

        return (
            <span className="image-gallery-thumbnail-inner">
                {item.originalTitle && (
                    <span
                        className="image-gallery-thumbnail-label"
                        title={item.originalTitle}
                    >
                        {item.originalTitle}
                    </span>
                )}
                <img
                    className="image-gallery-thumbnail-image"
                    src={getThumbnail(item.thumbnail, fileFormat)} 
                    alt={item.originalTitle}
                />
            </span>
        )
    }

    useEffect(() => {
        if (mode === 'fullscreen' && ref?.current) {
            ref.current.fullScreen()
        }
    }, [ref, mode, show])

    return (
        <>
            {show && (
                <ImageGallery
                    ref={ref}
                    items={files}
                    renderItem={renderItem}
                    renderThumbInner={renderThumbInner}
                    showPlayButton={false}
                    startIndex={startIndex}
                    onScreenChange={handleScreenChange}
                    lazyLoad={true}
                />
            )}
        </>
    )
}

FileGallery.defaultProps = defaultProps

export default FileGallery