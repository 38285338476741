import React, { useState, useContext, createContext, useEffect } from "react";
import { AcessoClient, IAcessoResponse, MenuClient, IMenuResponse, AcessoResponse, FuncionalidadeClient, IFuncionalidadeResponse } from '../services/auth'
import Config from '../config/config'
import { LoadingProvider } from './'
import { encrypt } from '../helpers'

type AuthProviderType = {
    children: React.ReactNode
}

interface AuthContextType {
  user: IAcessoResponse | null;
  menu?: IMenuResponse[] | null;
  funcionalidade?: IFuncionalidadeResponse[] | null;
  signin(username: string, password: string): Promise<void>;
  signout(): void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

function Provider({ children } : AuthProviderType ) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  const { API_BASE_URL } = Config;

  const [user, setUser] = useState<IAcessoResponse | null>(null);
  const [menu, setMenu] = useState<IMenuResponse[] | null>(null);
  const [funcionalidade, setFuncionalidade] = useState<IFuncionalidadeResponse[] | null>(null);
  
  const UserService = new AcessoClient(API_BASE_URL);
  const MenuService = new MenuClient(API_BASE_URL);
  const FuncionalidadeService = new FuncionalidadeClient(API_BASE_URL);
  
  const { APP_GUID, APP_PRIVATE_GUID } = Config;
  
  const { setLoading } = LoadingProvider.useLoading();

  const signin = (username: string, password: string): Promise<void> => {

    let encrypted = encrypt({
      app: APP_PRIVATE_GUID,
      login: username,
      password: password
    });
    
    return UserService
      .loginjs(APP_GUID, encrypted)
      .then((userSession: AcessoResponse) => {

        if (!userSession.token) throw new Error('Token não está presente na resposta');

        setUser(userSession)
        setLoading(true)

        let session = {
          user: userSession,
          menu,
          funcionalidade
        }

        Promise
        .all([
          FuncionalidadeService.setBearer(userSession.token).usuarioModulo(3),
          MenuService.setBearer(userSession.token).modulo(3)
        ])
        .then(res => {
          let funcionalidadeList: any = {};
          
          res[0].map(item => {
            if (item.id) {
              funcionalidadeList[item.id] = item;
            }
          })

          setFuncionalidade(funcionalidadeList)
          setMenu(res[1])
          
          session = {
            ...session,
            funcionalidade: funcionalidadeList,
            menu: res[1]
          }

          localStorage.setItem('@lm/user-session', JSON.stringify(session))
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          return err;
        })

      })
      .catch((err) => {
          throw err;
      })

  };
  
  const signout = () => {
    setUser(null);
    setMenu(null)
    setFuncionalidade(null)
    localStorage.removeItem('@lm/user-session')
  };

  useEffect(() => {
    const userSession = localStorage.getItem('@lm/user-session')
    if (!userSession) {
      setLoading(false)
      return;
    };
    
    const userSessionObj = JSON.parse(userSession);
    const sessionExpires = new Date(userSessionObj.user?.expiration);
    
    if (sessionExpires <= new Date()) {
      signout()
    } else {
      setUser(userSessionObj.user)
      setMenu(userSessionObj.menu)
      setFuncionalidade(userSessionObj.funcionalidade)
    }

    setLoading(false)

  }, [localStorage.getItem('@lm/user-session')])

  return {
    user,
    menu,
    funcionalidade,
    signin,
    signout
  };
}

const AuthProvider = {
  useAuth,
  Provider
}

export default AuthProvider