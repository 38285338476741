(() => {
    const pad = (number: number) => {
		if (number < 10) {
			return '0' + number;
		}
		return number;
	}

	Date.prototype.toISOString = function() {
		return this.getFullYear() +
			'-' + pad(this.getMonth() + 1) +
			'-' + pad(this.getDate()) +
			'T' + pad(this.getHours()) +
			':' + pad(this.getMinutes()) +
			':' + pad(this.getSeconds()) +
			'.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5);
	};	

})();

export default {}
