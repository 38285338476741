import React from 'react'
import { useLocation } from "react-router-dom"
import { Content, Title } from '../../components'
import { TitleProps } from '../../components/Title'
import { AuthProvider } from '../../providers'
import { IMenuResponse } from '../../services/auth'
import './style.scss'

export interface Props {
    children: React.ReactNode,
    statusBar?: React.ReactNode,
    titleProps?: TitleProps
}

const DefaultLayout = (props: Props) => {

    const { children, statusBar, titleProps } = props;
    const { user, menu, signout } = AuthProvider.useAuth();
    const location = useLocation();
    const pathname = location?.pathname.substring(1).replace(/\d+/, ':id');

    const getPathTree = (current: string, list = menu) => {
        if (!list) return;

        const listCopy = JSON.parse(JSON.stringify(list));
        
        const result = listCopy.filter(function f(item: IMenuResponse) : any {
          if (item.path === current) return true
        
          if (item.items) {
            return (item.items = item.items.filter(f)).length
          }
        })

        return result;
    }

    const pathTree = getPathTree(pathname)
    
    return (
        <div className="default-layout">
            <Content user={ user?.name } signout={ signout } statusBar={statusBar}>
                { titleProps && pathTree && (
                    <div className="default-layout__header">
                        { titleProps && (
                            <Title title={ titleProps.title } icon={ titleProps.icon } />
                        )}
                    </div>
                )}
                <div className="default-layout__body">
                    { children }
                </div>
            </Content>
        </div>
    )
}

export default DefaultLayout