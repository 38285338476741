import { useState, useEffect } from 'react'
import _ from 'lodash'
import Icon from '@material-ui/core/Icon';
import { Button, Checkbox, Spinner } from '../'
import './style.scss'

interface Column {
    name: string,
    label: string
}

interface DataItem {
    [key: string]: any
}

interface Props {
    columns: Column[],
    options: DataItem[],
    value: DataItem[],
    fetching?: boolean,
    isMulti?: boolean,
    onChange(list: any[]): void,
}

const ManyToMany = (props: Props) => {

    const { columns, options, value, fetching = false, isMulti = false, onChange } = props;

    const [internalOptions, setInternalOptions] = useState<any[]>([]);
    const [internalValue, setInternalValue] = useState<any[]>([]);
    const [optionsCheckAll, setOptionsCheckAll] = useState<boolean>(false);
    const [valueCheckAll, setValueCheckAll] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('');
    const [sortByOrder, setSortByOrder] = useState<string>('asc');

    const handleOptionsCheck = (list: any[]) => {
        let optionsCopy = [ ...internalOptions ]
        if (!isMulti) {
            optionsCopy = optionsCopy.map(item => ({ ...item, checked: item.raw.id === list[0] ? item.checked : false }))
        }
        
        list.map(id => {
            const itemIndex = optionsCopy.findIndex(item => item.raw.id === id);
            optionsCopy[itemIndex].checked = list.length > 1 ? !optionsCheckAll : !optionsCopy[itemIndex].checked
            isMulti && optionsCopy[itemIndex].checked !== optionsCheckAll && setOptionsCheckAll(false)
        })

        setInternalOptions(optionsCopy)
        !isMulti && onChange(optionsCopy.filter(item => item.checked))
        list.length > 1 && setOptionsCheckAll(!optionsCheckAll)
    }

    const handleValueCheck = (list: any[]) => {
        const valueCopy = [ ...internalValue ]

        list.map(id => {
            const itemIndex = valueCopy.findIndex(item => item.raw.id === id);
            valueCopy[itemIndex].checked = list.length > 1 ? !valueCheckAll : !valueCopy[itemIndex].checked
            valueCopy[itemIndex].checked !== valueCheckAll && setValueCheckAll(false)
        })

        setInternalValue(valueCopy)
        list.length > 1 && setValueCheckAll(!valueCheckAll)
    }

    const handleAdd = () => {
        onChange([
            ...internalValue,
            ...internalOptions.filter(item => item.checked)
        ])

        internalOptions.filter(item => !item.checked).length === 0 && setOptionsCheckAll(false)
    }

    const handleRemove = () => {

        !isMulti && handleOptionsCheck(internalValue.filter(item => item.checked).map(item => item.value))

        if (isMulti) {
            onChange([
                ...internalValue.filter(item => !item.checked)
            ])
        } else {
            onChange([])
        }

        internalValue.filter(item => !item.checked).length === 0 && setValueCheckAll(false)
    }

    const sortDataBy = (column: string) => {
        const order = sortByOrder == 'asc' && sortBy === column ? 'desc' : 'asc';
        
        setInternalOptions(_.orderBy(internalOptions, item => item.raw[column], [order]))
        setSortBy(column)
        setSortByOrder(order)
    }

    let valueDependency;
    if (value.constructor === Array && value[0]) {
        valueDependency = value.map(item => item.value).join()
    } else {
        valueDependency = value
    }
    
    useEffect(() => {
        if (isMulti) {
            setInternalOptions(options.filter(item => value.map(item => item.raw && item.raw.id).indexOf(item.raw.id) < 0).map(item => ({ ...item, raw: item.raw, checked: false })))
        } else {
            setInternalOptions(
                options
                .map(item => {
                    
                    let itemValue = false;
                    let foundItem = internalOptions.find(option => option.raw.id === item.raw.id)

                    if (foundItem) {
                        itemValue = foundItem.checked;
                    } else if (value[0] && value[0].raw && item.raw && value[0].raw.id === item.raw.id) {
                        itemValue = value[0].checked;
                    }

                    return { 
                        ...item, 
                        raw: item.raw, 
                        checked: itemValue
                    }
                })
            )
        }
        setInternalValue(value.filter(item => item).map(item => ({ ...item, raw: item.raw, checked: false })))
    }, [options.length, valueDependency])

    return (
        <div className="many-to-many">
            <div className="many-to-many__list many-to-many__list--options">
                <div className="many-to-many__list__header">
                        <div className="many-to-many__list__header__column many-to-many__list__header__column--check">
                            { isMulti && (
                                <Checkbox type="checkbox" checked={optionsCheckAll} onChange={ () => handleOptionsCheck(internalOptions.map(item => item.raw.id)) } />
                            )}
                        </div>
                    {
                        columns.map((item: any, index: number) => (
                            <div key={index} className="many-to-many__list__header__column" onClick={ () => sortDataBy(item.name) }>
                                <div className="many-to-many__list__header__column__label">
                                    { item.label }
                                </div>
                                <div className={ `many-to-many__list__header__column__sort ${sortBy === item.name ? 'many-to-many__list__header__column__sort--active' : ''}` }>
                                    <Icon>unfold_more</Icon>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="many-to-many__list__data">
                    { fetching && (
                        <div className="many-to-many__loading">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                    {
                        internalOptions && internalOptions.filter(item => item.raw && internalValue.map(item => item.value).indexOf(item.value) === -1).map((item: any, index: number) => (
                            <div key={index} className="many-to-many__list__data__item" onClick={ () => handleOptionsCheck([item.raw.id]) }>
                                <div className="many-to-many__list__data__column many-to-many__list__data__column--check">
                                    <Checkbox type="checkbox" checked={ item.checked } onChange={ () => {} } />
                                </div>
                                {
                                    columns.map((column: any, index2: number) => (
                                        <div key={index2} className="many-to-many__list__data__column">
                                            { item.raw[column.name] }
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="many-to-many__list__footer">
                    <div className="many-to-many__list__footer__count">
                        { options.length } resultado(s) encontrado(s)
                    </div>
                    { isMulti && (
                        <Button className="many-to-many__list__footer__btn-add" variant="link" onClick={ handleAdd }>
                            Selecionar
                        </Button>
                    )}
                </div>
            </div>
            <div className="many-to-many__list many-to-many__list--selected">
                <div className="many-to-many__list__header">
                    { isMulti && (
                    <div className="many-to-many__list__header__column many-to-many__list__header__column--check">
                        <Checkbox type="checkbox" checked={valueCheckAll}  onChange={ () => handleValueCheck(internalValue.map(item => item.raw.id)) } />
                    </div>
                    )}
                    <div className="many-to-many__list__header__column">Selecionados</div>
                </div>
                <div className="many-to-many__list__data">
                { 
                    internalValue.map((item: any, index: number) => (
                        <div className="many-to-many__list__data__item" key={index} onClick={ () => handleValueCheck([item.raw.id]) }>
                            { isMulti && (
                                <div className="many-to-many__list__data__column many-to-many__list__data__column--check">
                                    <Checkbox type="checkbox" checked={ item.checked } onChange={ () => {} } />
                                </div>
                            )}
                            <div className="many-to-many__list__data__column">
                            {
                                columns.map((column: any, index2: number) => (
                                    <div key={index2} className="many-to-many__list__data__item__column">
                                        { item.raw && item.raw[column.name] }
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    ))
                }
                </div>

                <div className={ `many-to-many__list__footer ${ isMulti ? 'many-to-many__list__footer--multi' : ''}` }>
                    { isMulti && (
                        <div className="many-to-many__list__footer__count">
                            { internalValue.length } selecionados
                        </div>
                    )}
                    { internalValue.length > 0 && (
                    <Button className="many-to-many__list__footer__btn-add" variant="link" onClick={ handleRemove }>
                        { isMulti ? "Remover" : "Limpar" }
                    </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ManyToMany