import { Form, FormCheckProps } from 'react-bootstrap'

interface CheckboxProps extends FormCheckProps {
    type: "checkbox" | "radio",
    errorMessage?: string
}

const LMCheckbox = (props: CheckboxProps) => {
    const { name, type, errorMessage, ...checkboxProps } = props;

    return (
        <Form.Group controlId={name} className="lm-checkbox">
            <Form.Check type={type} name={name} { ...checkboxProps } />
            { errorMessage && (
            <Form.Control.Feedback type="invalid">
                  {errorMessage}
            </Form.Control.Feedback>
            )}
        </Form.Group>
    )
}
export type { CheckboxProps }
export default LMCheckbox