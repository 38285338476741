import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { AuthProvider } from '../../providers'
import Config from '../../config/config'
import Messages from '../../config/messages'
import './ocorrenciaAcidente.scss'
import '../../assets/scss/_modal.scss';
import {
    Checkbox,
    Input,
    Card,
    Button,
    Table,
    Modal,
    DatePicker,
} from '../../components'
import {
    AcidenteClient,
    AcidenteVitimaClient,
    IAcidenteRequest,
    IAcidenteVitimaRequest,
    IAcidenteTerceiroRequest,
    AcidenteRequest,
    AcidenteVitimaRequest,
    AcidenteTerceiroRequest,
    AcidenteTerceiroClient,
} from '../../services/manutencao'
import {
    ResponsavelClient,
    PopulaResponseItem
} from '../../services/geral'

interface Values extends IAcidenteRequest {
    
}

interface Vitimas extends IAcidenteVitimaRequest {
    
}

interface Terceiro extends IAcidenteTerceiroRequest {
}

interface VitimasErro {
    nome: boolean,
    situacao: boolean,
    telefone: boolean,
}

interface TerceiroErro {
    nome: boolean,
    placa: boolean,
    telefone: boolean,
}

interface Props {
    idOcorrencia?: number,
    podeAlterar?: boolean,
    valuesTerceiros: any
}

/** Tipo (1: Locadora; 2: Terceiro; 3: Indefinido) */
export enum CulpadoEnum {
    Locadora = 1,
    Terceiro = 2,
    Indefinido = 3,
}

const OcorrenciaAcidente = forwardRef((props:Props, ref) => {  

    const { API_BASE_URL } = Config;
    const { user } = AuthProvider.useAuth();
    const { idOcorrencia, podeAlterar, valuesTerceiros } = props;

    const AcidenteService = new AcidenteClient(API_BASE_URL).setBearer(String(user?.token));
    const AcidenteVitimaService = new AcidenteVitimaClient(API_BASE_URL).setBearer(String(user?.token));
    const AcidenteTerceiroService = new AcidenteTerceiroClient(API_BASE_URL).setBearer(String(user?.token));
    const responsavelService = new ResponsavelClient(API_BASE_URL).setBearer(String(user?.token));

    // const [responsavelFullList, setResponsavelFullList] = useState<PopulaResponseItem[]>([])
    
    const [initialValues, setInitialValues] = useState<Values>({
        responsavelId: CulpadoEnum.Locadora        
    });

    // const [culpabilidadeMissing, setCulpabilidadeMissing] = useState<boolean>(false);        
    // const [dataMissing, setDataMissing] = useState<boolean>(false);        

    const [vitima, setVitima] = useState<Vitimas>({});
    const [vitimaIdAux, setVitimaIdAux] = useState<number>(0);
    const [vitimaDelete, setVitimaDelete] = useState<number[]>([]);
    const [itemsVitima, setItemsVitima] = useState<Vitimas[]>([]);
    const [showModalVitima, setShowModalVitima] = useState<boolean>(false);
    const [vitimaErro, setVitimaErro] = useState<VitimasErro>({ nome: false, telefone: false, situacao: false } as VitimasErro);

    const [terceiroIdAux, setTerceiroIdAux] = useState<number>(0);
    const [terceiroDelete, setTerceiroDelete] = useState<number[]>([]);
    const [terceiro, setTerceiro] = useState<Terceiro>({ });
    const [itemsTerceiro, setItemsTerceiro] = useState<Terceiro[]>([]);
    const [showModalTerceiro, setShowModalTerceiro] = useState<boolean>(false);
    const [terceiroErro, setTerceiroErro] = useState<TerceiroErro>({ nome: false, placa: false, telefone: false} as TerceiroErro);

    const [loadingVitima, setLoadingVitima] = useState<boolean>(false);
    const [loadingTerceiro, setLoadingTerceiro] = useState<boolean>(false);
    
    const location = useLocation();

    const accessObj = {
        create: podeAlterar,
        read: podeAlterar,
        update: podeAlterar,
        delete: podeAlterar,
    }

    useEffect(() => { 
        if(idOcorrencia) {
            setLoadingVitima(true)
            setLoadingTerceiro(true)

            AcidenteService
            .acidenteGet(idOcorrencia ?? 0)
            .then(res => {
                                
                setInitialValues({...res});
            })
            .catch(e => console.log(e));

            AcidenteVitimaService
            .listaByOcorrencia(idOcorrencia)
            .then(res => {
                setItemsVitima(res.itens?.map((item) => ({...item} as Vitimas)) ?? []);
                setLoadingVitima(false)

            })
            .catch(e => setLoadingVitima(false));
            
        }

        // responsavelService
        // .populaAcidente(null)
        // .then(res => {
        //     if (!res.itens) return;

        //     setResponsavelFullList(res.itens)
        // })
        // .catch(res => console.log(res))

    }, [])

    useImperativeHandle(ref, () => ({
        submitAcidente() {            
            handleFormSubmit();
            // handleFormSubmitVitima();
            // handleFormSubmitTerceiro();
        },
        getModelAcidente() {
            return {
                itemsVitima: itemsVitima
            }
        }
    }));

    const handleFormSubmit = () => {

        const objRequest = {...initialValues} as AcidenteRequest;

        objRequest.id = idOcorrencia;
        objRequest.entityVersion = undefined;
        objRequest.dataAceidente = valuesTerceiros?.dataAcidente;        

        if(!initialValues.entityVersion) {
            AcidenteService
            .acidentePost(objRequest)
            .then(res => {                

                setInitialValues(prevState => ({
                    ...prevState,
                    id: res.id,
                }));

            })
            .catch(e => console.log("Erro: acidentePost", e))
        }
        else {
            AcidenteService
            .acidentePut(objRequest.id ?? 0, objRequest)
            .then(res => {

                setInitialValues(prevState => ({
                    ...prevState,
                    id: res.id,
                }));

            })
            .catch(e => console.log("Erro: acidentePost", e))
        }

        handleFormSubmitVitima();
        handleFormSubmitTerceiro();        
        
    }

    const handleFormSubmitVitima = () => {
        itemsVitima.forEach((value) =>{
            const objRequest = {...value} as AcidenteVitimaRequest;

            objRequest.entityVersion = undefined;
            objRequest.ocorrenciaId = idOcorrencia;

            if(value.id && value.id > 0) {
                AcidenteVitimaService
                .acidenteVitimaPut(objRequest.id ?? 0, objRequest)
                .then()
                .catch(e => console.log("Erro: acidenteVitimaPut", e))
            }
            else {
                objRequest.id = undefined;

                AcidenteVitimaService
                .acidenteVitimaPost(objRequest)
                .then(res => {
                    
                    setItemsVitima(
                        itemsVitima.map((e) => {
                            e.id = res.id;
    
                            return e;
                        })
                    )
                })
                .catch(e => console.log("Erro: acidenteVitimaPut", e))
            }

            vitimaDelete.forEach(e => {
                if(e > 0) {
                    AcidenteVitimaService
                    .acidenteVitimaDelete(e)
                    .then()
                    .catch(e => console.log("Erro: acidenteVitimaDelete", e))
                }
            });
        })
    }

    const handleFormSubmitTerceiro = () => {
        let itemTerceiro: Terceiro[] = []                    
        let numTelefone = (valuesTerceiros?.telefoneTerceiro ?? "").replace(/[^\d]+/g, '');
        if(valuesTerceiros){
            const objTerceiro: Terceiro = {
                id: undefined,
                nomeTerceiro: valuesTerceiros?.nomeTerceiro ?? "",
                numPlaca: valuesTerceiros?.placaTerceiro ?? "",
                numDdd: numTelefone ? numTelefone.substring(0,2) : "",
                numTelefone: numTelefone ? numTelefone.substring(2) : "",
                possuiSeguro: valuesTerceiros?.possuiSeguro,
                nomeSeguradora: valuesTerceiros?.nomeSeguradora,
                numProtocoloSeguradora: valuesTerceiros?.numProtocoloSeguradora
            } as Terceiro;

            itemTerceiro = [objTerceiro]            
        }

        itemTerceiro.forEach((value) => {
            const objRequest = {...value} as AcidenteTerceiroRequest;

            objRequest.ocorrenciaId = idOcorrencia;
            objRequest.entityVersion = undefined;

            if(value.id && value.id > 0) {
                AcidenteTerceiroService
                .acidenteTerceiroPut(objRequest.id ?? 0, objRequest)
                .then()
                .catch(e => console.log("Erro: acidenteTerceiroPut", e))
            }
            else {
                
                AcidenteTerceiroService
                .acidenteTerceiroPost(objRequest)
                .then(res => {
                    
                })
                .catch(e => console.log("Erro: acidenteTerceiroPut", e))
            }
        })
    }

    const handleEditarVitima = (editVitima: Vitimas)  => {
        editVitima.numTelefone =( editVitima.numDdd ?? "") + (editVitima.numTelefone ?? "");

        setVitima({...editVitima});

        setVitimaErro({nome: false, telefone: false, situacao: false});

        setShowModalVitima(true);
    }

    const handleEditarTerceiro = (editTerceiro: Terceiro)  => {
        editTerceiro.numTelefone =( editTerceiro.numDdd ?? "") + (editTerceiro.numTelefone ?? "");

        setTerceiro({...editTerceiro});
        setTerceiroErro({nome: false, telefone: false, placa: false});

        setShowModalTerceiro(true);
    }

    const handleCloseModalVitima = () => {
        setVitima({});
        setShowModalVitima(false);
        setVitimaErro({nome: false, telefone: false, situacao: false});
    }

    const handleVitima = () => {
        let vitimaItem = {...vitima}; 
        if(!vitimaValida(vitimaItem)) {
            return;
        }

        (vitimaItem.id) 
            ? handleEditVitima(vitimaItem)
            : handleAddVitima(vitimaItem);
    }    

    const vitimaValida = (vitimaItem: Vitimas) => {

        vitimaItem.numTelefone = (vitimaItem.numTelefone ?? "").replace(/[^\d]+/g, '');

        setVitimaErro(preState => ({ 
            ...preState, 
            telefone: (vitimaItem.numTelefone ?? "").length < 10
                && (vitimaItem.numTelefone ?? "").length > 0, 
            nome: (vitima.nomeVitima ?? "").length === 0 || (vitima.nomeVitima ?? "").length > 250,
            situacao: (vitima.situacaoVitima ?? "").length === 0 || (vitima.situacaoVitima ?? "").length > 250,
        }));

        return !((vitima.nomeVitima ?? "").length === 0 || (vitima.nomeVitima ?? "").length > 250
            || (vitima.situacaoVitima ?? "").length === 0 || (vitima.situacaoVitima ?? "").length > 250
            || (vitimaItem.numTelefone.length < 10 && vitimaItem.numTelefone.length > 0)) 
    }

    const handleEditVitima = (vitimaItem: Vitimas) => {

        let vitimaItens = [...itemsVitima];
        const index = vitimaItens.findIndex(e => e.id === vitimaItem.id);

        vitimaItens[index] = {...vitimaItem};
        vitimaItens[index].numDdd = (vitimaItem.numTelefone ?? "").substring(0,2);
        vitimaItens[index].numTelefone = (vitimaItem.numTelefone ?? "").substring(2);
        
        setVitima({});
        setItemsVitima(vitimaItens);
        setVitimaErro({nome: false, telefone: false, situacao: false});
        
        setShowModalVitima(false);
    }

    const handleAddVitima = (vitimaItem: Vitimas) => {

        vitimaItem.numDdd = (vitimaItem.numTelefone ?? "").substring(0,2);
        vitimaItem.numTelefone = (vitimaItem.numTelefone ?? "").substring(2);
        vitimaItem.id = vitimaIdAux -1;
        setVitimaIdAux(vitimaItem.id);
        
        setItemsVitima(prevState => ([ ...prevState, vitimaItem ]));
        setVitima({});
        setVitimaErro({nome: false, telefone: false, situacao: false});
        
        setShowModalVitima(false);
    }

    const handleDeleteVitima = (data: any) : Promise<boolean> => {
        let items = [...itemsVitima]        

        return new Promise((resolve, reject) => {
            const indexProc = itemsVitima?.findIndex(e => e.id === data.id);
                
            if(indexProc > -1) {                
                items.splice(indexProc, 1);

                setItemsVitima(items);
                setVitimaDelete(prevState => ([...prevState, (data.id ?? 0)]))
                
                resolve(true);
            }
            else {
                reject(false);
            }
        })
    }

    const handleDeleteTerceiro = (data: any) : Promise<boolean> => {
        let items = [...itemsTerceiro]
        
        return new Promise((resolve, reject) => {
            const indexProc = itemsTerceiro?.findIndex(e => e.id === data.Editar.id);
                
            if(indexProc > -1) {
                resolve(true);
                items.splice(indexProc, 1);
                setItemsTerceiro(items);
                setTerceiroDelete(prevState => ([...prevState, (data.Editar.id ?? 0)]))
            }
            else {
                reject(false);
            }
        })
    }

    const columnsVitima = [
        {
            Header: 'Nome',
            accessor: 'nomeVitima',
        },
        {
            Header: 'Telefone',
            accessor: (row: any) =>  row,
            Cell: ({ value } : any) => "(" + value?.numDdd + ")" + value?.numTelefone
        },
        {
            Header: 'Situação Vítima',
            accessor: 'situacaoVitima',
            
        }
    ]

    const columnsTerceiro = [
        {
            Header: 'Nome',
            accessor: 'nomeTerceiro',
        },
        {
            Header: 'Placa',
            accessor: 'numPlaca' 
        },
        {
            Header: 'Telefone',
            accessor: (row: any) =>  row,
            Cell: ({ value } : any) => "(" + value?.numDdd + ")" + value?.numTelefone
        },
        {
            Header: 'Possui seguro',
            accessor: (row: any) =>  row,
            Cell: ({ value } : any) => value?.possuiSeguro ? "Sim" : "Não"
        },
        {
            Header: 'Seguradora',
            accessor: 'nomeSeguradora' 
        },
        {
            Header: 'Protocolo Seguradora',
            accessor: 'numProtocoloSeguradora' 
        }
    ]

    const tablePropsVitima = {
        title: "Vítimas",        
        columns: columnsVitima,
        data: itemsVitima ?? [],
        handleEdit: (cell: any) => handleEditarVitima(cell.row.original),
        handleDelete: handleDeleteVitima,
        deleteRowTable: false,
        enableColumnHide: false,
        enableExport: false,
        enablePrint: false,
        enablePagination: false,
        access: { ...accessObj }
    }

    const tablePropsTerceiros = {
        title: "Terceiros",        
        columns: columnsTerceiro,
        data: itemsTerceiro ?? [],
        handleEdit: (cell: any) => handleEditarTerceiro(cell.row.original),
        handleDelete: handleDeleteTerceiro,
        deleteRowTable: false,
        enableColumnHide: false,
        enableExport: false,
        enablePrint: false,
        enablePagination: false,
        access: { ...accessObj }
    }

    return (
        <>
            <Card className="p-4 p-4-top15">
                {/* <div className="grid-filter__header">
                    <h1 className="lm-title lm-title-100">
                        <Col xs={8}>
                            <span className="material-icons" >directions_car</span>
                            Acidente
                        </Col>
                    </h1>
                </div> */}

                {/* <Row>
                    <Col xs={6}>
                        <div className="ocorrencia-search__form__date">
                            <span className="ocorrencia-search__form__date__label">Culpabilidade</span>

                            {
                                responsavelFullList.map((item, index) => {
                                    return (
                                        <Checkbox                                       
                                            id={ `culpabilidade_check${index}` }     
                                            name={ `culpabilidade_check${index}` }
                                            type="radio"
                                            label={ item.nome }
                                            checked={ initialValues.responsavelId === item.id  }
                                            onChange={ () => {
                                                setInitialValues(prevState => ({
                                                        ...prevState, 
                                                        responsavelId: item.id
                                                    }) 
                                                )                                                
                                            }}
                                            disabled={ !podeAlterar }
                                        />
                                    )
                                })
                            }                                      
                        </div>
                        
                    </Col>
                    <Col xs={6}>
                        <DatePicker
                        name="dataAcidente"
                        label="Data do Acidente"
                        icon="event"
                        selected={initialValues.dataAceidente}
                        onChange={(date: Date | null) => {
                            setInitialValues(prevState=>({
                                ...prevState,
                                dataAceidente: date ?? undefined
                            }));
                        }}
                        size="lg"
                        mode="datetime"
                        isInvalid={dataMissing} 
                        errorMessage={Messages.form.required}
                        />
                    </Col>
                </Row>

                <hr className="lm-line" /> */}

                <Row>
                    <Col xs={3}>
                        <span className="lm-form-title__subTitle">
                            <b>Vítimas do acidente</b>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}> 
                        <Table {...tablePropsVitima} loading={loadingVitima} />
                    </Col>
                </Row>
                
                {
                    podeAlterar &&
                    <Row>
                        <Col>
                            <Button variant="primary" className="float-right mt-4" onClick={() => {setShowModalVitima(true)}}>
                                <span>
                                    Adicionar Vítima
                                </span>
                            </Button>
                        </Col>
                    </Row>
                }

            </Card>

            <Modal
                show={showModalVitima}
                onHide={handleCloseModalVitima}           
                size="lg"
                className="lm-modal-observacao" 
                centered
            >
                <Modal.Header>
                    <Modal.Title className="lm-modal-observacao__title">      
                            {vitima.id && "Editar "}
                            {!vitima.id && "Adicionar "}
                        Vítima
                    </Modal.Title>                    
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12}>
                            <Input       
                                name="nomeVitima"
                                label="Nome"
                                size="lg"
                                value={vitima.nomeVitima}
                                onChange={(e)=> setVitima(prevState => ({...prevState, nomeVitima: e.target.value}))}
                                isInvalid={vitimaErro.nome}
                                errorMessage={(vitima.nomeVitima ?? "").length === 0 ? Messages.form.required : Messages.form.maxLength250}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <Input       
                                name="situacaoVitima"
                                label="Situação Vítima"
                                size="lg"
                                value={vitima.situacaoVitima}
                                onChange={(e)=> setVitima(prevState => ({...prevState, situacaoVitima: e.target.value}))}
                                isInvalid={vitimaErro.situacao}
                                errorMessage={(vitima.situacaoVitima ?? "").length === 0 ? Messages.form.required : Messages.form.maxLength250}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <Input       
                                name="telefone"
                                label="Telefone"
                                size="lg"
                                mask="(11)111111111"
                                value={vitima.numTelefone}
                                onChange={(e)=> setVitima(prevState => ({...prevState, numTelefone: e.target.value}))}
                                isInvalid={vitimaErro.telefone}
                                errorMessage={Messages.form.numInvalido}
                            />
                        </Col>
                    </Row>

                    <Button variant="primary" className="float-right lm-modal-observacao__btn-confirm ml-3" onClick={ () => handleVitima()}>
                            {vitima.id && "Atualizar"}
                            {!vitima.id && "Adicionar"}
                    </Button>

                    <Button variant="outline-primary" className="float-right"  onClick={ () => handleCloseModalVitima()}>                                    
                        Voltar
                    </Button>    

                </Modal.Body>
            </Modal>
        </>
    )
})

export default OcorrenciaAcidente