import { Alert, AlertProps } from 'react-bootstrap'
import './style.scss'

const LMAlert = (props: AlertProps) => (
    <Alert className="lm-alert" {...props}>
        {props.children}
    </Alert>
)


export default LMAlert