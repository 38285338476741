import { SeguradoraScreen } from '../screens'

const Routes = {
    FormularioSeguradoraAdd: {
        name: 'Formulário Seguradora',
        path: '/formulario-seguradora/add',
        component: SeguradoraScreen
    },
    FormularioSeguradoraId: {
        name: 'Formulário Seguradora',
        path: '/formulario-seguradora/:id/:tab?',
        component: SeguradoraScreen
    }
}

export default Routes