import { DefaultLayout } from "../../layouts"
import { Tabs } from '../../components'
import CadastroBasicoFormScreen from "./cadastroBasicoFormScreen"
import { useState, useEffect } from "react";
import Documento from './documento'
import { RouteComponentProps, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import Messages from '../../config/messages'
import { RegraEspecialClient } from "../../services/auth";
import { AuthProvider } from "../../providers";
import Config from "../../config/config";
import RegraEspecial from "../../config/regraespecial";
import { Spinner, SpinnerProps } from 'react-bootstrap'

interface RouteParams {
    id: string;
    tab: string;
}

const SeguradoraFormScreen = ({ match }: RouteComponentProps<RouteParams>) => {
    
    const { user } = AuthProvider.useAuth();

    const regraEspecialService = new RegraEspecialClient(Config.API_BASE_URL).setBearer(String(user?.token));

    const [currentTab, setCurrentTab] = useState<string>("cadastroBasico");    

    const [hasOC001_SEG, setHasOC001_SEG] = useState<boolean>(false);    

    const [loadingOC001_SEG, setLoadingOC001_SEG] = useState<boolean>(true);    

    const { id } = match.params;

    const { search } = useLocation();        
    
    useEffect(() => {        
        if (!!id) {
            setCurrentTab("documento");
        }
    }, [id])

    useEffect(() => {        
        if (!!search) {            
            if(search.indexOf('success') > -1){
                toast.success(Messages.form.saveSuccess)
            }
        }

        regraEspecialService
        .usuarioHas(RegraEspecial.OC001_SEG.sigla)
        .then(res => {            
            setHasOC001_SEG(res)

            setLoadingOC001_SEG(false)
        })

    }, [])

    return (
        <DefaultLayout 
            titleProps={ { title: "Formulário Seguradora", icon: "content_paste" } }
        >
            {
                loadingOC001_SEG && (
                    <Spinner animation="border" variant="primary" />
                )
            }

            {
                (!loadingOC001_SEG && hasOC001_SEG)
                && (

                    <Tabs
                        list={
                            [
                                {
                                    'eventKey': 'cadastroBasico',
                                    'title': 'Cadastro Básico',
                                    'content': <CadastroBasicoFormScreen changeTab={setCurrentTab} />,
                                    'disabled': !!id
                                },
                                {
                                    'eventKey': 'documento',
                                    'title': 'Documento',
                                    'content': !id
                                        ? <div>Código de ocorrência necessário.</div>
                                        : <Documento id={+id} changeTab={setCurrentTab} currentTab={currentTab} />,
                                    'disabled': !id
                                },
                            ]
                        }
                        activeKey={currentTab}
                        changeTab={setCurrentTab}
                    />

                )                
            }

            {
                (!loadingOC001_SEG && !hasOC001_SEG)
                && (                                        
                    <div className="lm-alert">
                        <span className="material-icons MuiIcon-root" aria-hidden="true">info</span>                                                                                    
                        Usuário não possui permissão para acessar formulário!
                    </div>
                )                
            }
            
        </DefaultLayout>
    )
}

export default SeguradoraFormScreen