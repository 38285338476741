import React from 'react'
import { Form, FormControlProps } from 'react-bootstrap'
import Icon from '@material-ui/core/Icon';
import MaskedInput from 'react-maskedinput'
import './style.scss'

interface InputProps extends FormControlProps {
    name: string,
    label?: string,
    icon?: string,
    placeholder?: string,
    errorMessage?: string,
    onKeyPress?(event: any): void,
    onBlur?(event: any): void,
    onFocus?(event: any): void,
    onIconClick?(event: any): void,
    mask?: string
}

const Input: React.FC<InputProps> = (props) => {

    const { errorMessage, icon, onIconClick, mask, ...inputProps } = props;

    return (
        <Form.Group controlId={ inputProps.name } className="lm-form-input">
            {
                mask
                ? <Form.Control {...inputProps} mask={mask} as={MaskedInput} />
                : <Form.Control {...inputProps} /> 
            }
            <Form.Label>{ inputProps.label }</Form.Label>
            { icon && (
                <Icon className={ `lm-form-input__icon ${ onIconClick ? 'lm-form-input__icon--action' : '' }` }  onClick={onIconClick}>{icon}</Icon>
            )}
            { errorMessage && (
            <Form.Control.Feedback type="invalid">
                  {errorMessage}
            </Form.Control.Feedback>
            )}
        </Form.Group>
    )
}

export default Input