import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { toast } from 'react-toastify';
import { Row, Col, ProgressBar } from 'react-bootstrap'
import { Table, Button, Form, Modal, Select, File as FileInput } from '../../components'
import { SelectFormatData, SelectOption } from '../../components/Select'
import { AuthProvider } from '../../providers'
import { 
    DocumentacaoClient,
    DocumentacaoPesquisaRequest,
    DocumentacaoPesquisaResponse,
    DocumentacaoRequest,
    DocumentacaoDeleteAllRequest,
    ListaDocumentosResponse,
    OcorrenciaClient,
 } from '../../services/manutencao'
import {
    FileClient,
    FileUploadBase64Request,
    IFileUploadBase64Request,
    FileUploadResponse
} from '../../services/storage'
import { 
    TipoDocumentoClient,
    PopulaResponseItem
 } from '../../services/geral'
import Config from '../../config/config'
import Messages from '../../config/messages'
import '../../assets/scss/_modal.scss';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DateTime } from 'luxon'
import "./documento.scss"
import Utils, { formatFilesViewed } from '../../helpers/utils'
import FileGallery from '../../components/FileGallery';
import { ArquivoSeguradoraRequest, OcorrenciaSeguradoraClient } from '../../services/externo';
import { useHistory } from 'react-router-dom'

enum StatusEnum {
    Aberta = 1,
    Cancelada = 2,
    Fechada = 3,
}

interface FileViewed {
    original: string
    thumbnail: string
    originalTitle: string
}
interface ViewFiles {
    show: boolean
    files: FileViewed[] | []
}

interface Props {
    changeTab(eventKey: string): void
    id: number,
    currentTab: string | undefined
}

const FormSchema = Yup.object().shape({
    tipoDocumento: Yup.number()
})

interface Values {
    tipoDocumentoId?: SelectOption,
    arquivos?: File[]
}

const tabKey = 'documento'

const DocumentoTab = (props: Props) => {

    const { API_BASE_URL } = Config;

    const { id, currentTab } = props;

    const { user } = AuthProvider.useAuth();

    const history = useHistory();

    const DocumentacaoService = new DocumentacaoClient(API_BASE_URL).setBearer(String(user?.token));
    const TipoDocumentoService = new TipoDocumentoClient(API_BASE_URL).setBearer(String(user?.token));
    const FileService = new FileClient(API_BASE_URL).setBearer(String(user?.token));
    const OcorrenciaService = new OcorrenciaClient(API_BASE_URL).setBearer(String(user?.token));
    const OcorrenciaSeguradoraService = new OcorrenciaSeguradoraClient(API_BASE_URL).setBearer(String(user?.token));

    const [initialValues, setInitialValues] = useState<Values>({        
        tipoDocumentoId: undefined,
        arquivos: undefined,
    })
    
    const defaultFetchConfig = new DocumentacaoPesquisaRequest({
        exibirTotais: true,
        paginaAtual: 1,
        itensPorPagina: 15,
        ocorrenciaId: +id
    })
    
    const [loading, setLoading] = useState<boolean>(false);
    const [pendenciaDocumento, setPendenciaDocumento] = useState<boolean>(false);
    const [items, setItems] = useState<DocumentacaoPesquisaResponse[]>([]);
    const [tipoDocumentoList, setTipoDocumentoList] = useState<PopulaResponseItem[]>([]);
    const [documentoEnviadoList, setDocumentoEnviadoList] = useState<ListaDocumentosResponse[]>([]);

    const [fetchConfig, setFetchConfig] = useState<DocumentacaoPesquisaRequest>(defaultFetchConfig)
    const [totalPages, setTotalPages] = useState<number | undefined>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(15);
    const [podeAlterar, setPodeAlterar] = useState<boolean>(true);
    const [viewFiles, setViewFiles] = useState<ViewFiles>({show: false, files: []})

    const [showConfirmeModal, setShowConfirmeModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showDeleteItemEnviadoModal, setShowDeleteItemEnviadoModal] = useState<boolean>(false);
    const [tipoDocumentoDelete, setTipoDocumentoDelete] = useState<number>();
    const [documentoEnviadoDelete, setDocumentoEnviadoDelete] = useState<number>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        OcorrenciaService
        .ocorrenciaGet(id)
        .then(res => {
            setPendenciaDocumento(res.indDocumento ?? false)
            setPodeAlterar(res.statusOcorrenciaId === StatusEnum.Aberta)
        })
        .catch(err => console.log(err))
    }, [])

    
    const columns = [
        {
            Header: 'Tipo de Documento',
            accessor: 'tipoDocumento'
        },
        {
            Header: 'Dt. Modificação',            
            accessor: (row: any) =>  row,
            Cell: ({ value } : any) => (
                <>
                    <span>
                        { DateTime.fromJSDate(value.dataModificacao).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                    </span>                                        
                </>
            )
        },
        {
            Header: 'Qtde. Anexos',            
            accessor: (row: any) =>  row,
            Cell: ({ value } : any) => (
                <>
                    <span>
                        { `${value.quantidadeAnexos} anexo(s)` }
                    </span>                                        
                </>
            )
        },
        {
            accessor: 'actions',
            disableSortBy: true,
            Header: () => (
                <>
                    {(
                        podeAlterar &&
                        <a className="lm-table__header__btn-add float-right" onClick={handleNewItem} title={ Messages.action.add }>                            
                            <AddIcon />
                        </a>
                    )}
                </>
            ),
            Cell: ({cell} : any) => (
                <>         

                    <div className="lm-table__actions">
                        <Button onClick={ () => handleOpenFileGallery(cell.row.original.tipoDocumentoId) } size="sm" variant="primary" className="mr-2 lm-table__actions__btn lm-table__actions__btn--edit">
                            <VisibilityIcon />
                        </Button>

                        <Button onClick={ () => handleEditItem(cell.row) } size="sm" variant="primary" className="mr-2 lm-table__actions__btn lm-table__actions__btn--edit">
                            <CreateIcon />
                        </Button>

                        <Button onClick={ () => handleDeleteItem(cell.row) } size="sm" variant="danger" className="lm-table__actions__btn lm-table__actions__btn--delete">
                            <DeleteIcon />
                        </Button>
                    </div>
                    

                    {/* {(
                        <Button onClick={ () => handleViewItem(cell.row) } size="sm" variant="primary" className="mr-2 lm-table__actions__btn lm-table__actions__btn--view float-right" title={ Messages.action.preview }>
                            <RemoveRedEyeIcon />
                        </Button>
                    )} */}
                </>
            )
        }
    ]

    const fetchData = () => {
        setLoading(true);

        DocumentacaoService
        .pesquisa(fetchConfig)
        .then((res) => {
            if (!res.itens) return;
            setTotalPages(res.totalPaginas)
            setItems(res.itens)
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => setLoading(false))

    }

    const fetchDataDocumentoList = () => {
        
        setTipoDocumentoList([])

        TipoDocumentoService
        .populaOcorrencia(+id, null)
        .then((res) => {
            if (!res.itens) return;
            
            setTipoDocumentoList(res.itens)            
        })
        .catch(err => {
            console.log(err)
        })        

    }

    const fetchDocumentoEnviadoList = (tipoDocumentoId: number) => {
        
        setDocumentoEnviadoList([])

        DocumentacaoService
        .listaByTipoDocumento(+id, tipoDocumentoId)
        .then((res) => {
            if (!res.itens) return;
            
            setDocumentoEnviadoList(res.itens)                        
        })
        .catch(err => {
            console.log(err)
        })        

    }
    
    const handlePageClick = (page: { selected: number }) => {
        fetchConfig.paginaAtual = page.selected+1;
        setFetchConfig(fetchConfig)
        setCurrentPage(fetchConfig.paginaAtual)
    }

    const handleChangePageSize = (pageSize: number) => {
        fetchConfig.itensPorPagina = pageSize;
        setFetchConfig(fetchConfig)
        setPageSize(fetchConfig.itensPorPagina)
    }
    
    const handleCloseModal = () => setShowModal(false);

    const handleCloseConfirmeModal = () => setShowConfirmeModal(false);

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleShowDeleteModal = () => setShowDeleteModal(true);

    const handleCloseDeleteItemEnviadoModal = () => setShowDeleteItemEnviadoModal(false);

    const handleShowDeleteItemEnviadoModal = () => setShowDeleteItemEnviadoModal(true);

    const handleDeleteItemEnviado = (id: number) => {                
        setDocumentoEnviadoDelete(id)        

        handleShowDeleteItemEnviadoModal()
    }

    const handleDeleteItem = (cell: any) => {                
        setTipoDocumentoDelete(cell.original.tipoDocumentoId)

        handleShowDeleteModal()
    }

    const handleConfirmDeleteModal = () => {

        var obj = {
            ocorrenciaId: +id,
            tipoDocumentoId: tipoDocumentoDelete
        } as DocumentacaoDeleteAllRequest

        DocumentacaoService
        .deleteAll(obj)
        .then((res) => {
            
            fetchData();

            toast.success(Messages.table.deleteSucess)

        })
        .catch(err => {                        
            toast.error(Utils.processErrorMessage(err))
        })        

        handleCloseDeleteModal()

    }

    const handleConfirmDeleteItemEnviadoModal = () => {
        
        DocumentacaoService
        .documentacaoDelete(documentoEnviadoDelete ?? 0)
        .then((res) => {
                    
            const documentoEnviadoListCopy = [...documentoEnviadoList]
            let indexDoc = documentoEnviadoListCopy.findIndex(e => e.documentoId === documentoEnviadoDelete);

            if(indexDoc>-1){
                documentoEnviadoListCopy.splice(indexDoc, 1)
            
                setDocumentoEnviadoList(documentoEnviadoListCopy)

                if(documentoEnviadoListCopy.length===0){
                    handleCloseModal()
                }
            }

            toast.success(Messages.table.deleteSucess)

            fetchData();

        })
        .catch(err => {                        
            toast.error(Utils.processErrorMessage(err))
        })        

        handleCloseDeleteItemEnviadoModal()

    }

    const handleOpenFileGallery = (tipoDocumentoId: number) => {
        DocumentacaoService
        .listaByTipoDocumento(+id, tipoDocumentoId)
        .then((res) => {
            if (!res.itens) return;
            
            setViewFiles({show: true, files: formatFilesViewed(res.itens)})                       
        })
        .catch(err => {
            console.log(err)
        })
    }

    const handleShowFileGallery = (isFullscreen: boolean) => {
		setViewFiles({...viewFiles, show: isFullscreen})
	}

    const handleEditItem = (cell: any) => {

        fetchDocumentoEnviadoList(cell.original.tipoDocumentoId)

        setIsNew(false)     

        setIsEditing(true)        

        setSaving(false)

        setInitialValues({ 
            tipoDocumentoId:  { value: cell.original.tipoDocumentoId ?? undefined, label: cell.original.tipoDocumento ?? '' } as SelectOption,
            arquivos: undefined
        })
        
        setShowModal(true)        
    }

    const handleNewItem = () => {
        setDocumentoEnviadoList([])
        
        fetchDataDocumentoList()

        setSaving(false)

        setIsNew(true)     
        setIsEditing(false)        

        setInitialValues({ tipoDocumentoId:  undefined, arquivos: undefined })
        
        setShowModal(true)        
    }

    const handleNewOcorrencia = () => {
        history.push(`/formulario-seguradora/add`) 
    }

    useEffect(() => {

        if (!id || currentTab !== tabKey) return;

        fetchData();        

    }, [id, currentTab, currentPage, pageSize])

    const tableProps = {
        title: "Documento",       
        idField: "tipoDocumentoId",
        columns,
        data: items,
        pageClick: handlePageClick,
        changePageSize: handleChangePageSize,
        totalPages: totalPages,
        initialPageSize: defaultFetchConfig.itensPorPagina        
    }

    // const submitForm = (docs: DocumentacaoRequest[]) => {
    //     const requests = docs.map(item => DocumentacaoService.documentacaoPost(item))

    //     Promise
    //     .all(requests)
    //     .then(res => {
    //         fetchData();
    //         toast.success(Messages.form.saveSuccess)
    //         setShowModal(false)            
    //     })
    //     .catch(err => {
    //         if (typeof err === 'object') {            
    //             if (err.hasOwnProperty('items')) {
    //                 for (var key in err['items']) {   
    //                     let item = err['items'][key]                     
    //                     let msgError = key + ': ' + item[0]
                                                
    //                     toast.error(msgError)
    //                 }

    //                 return;
    //             }
    //         }
            
    //         toast.error(Messages.form.saveError)
    //     })
    //     .finally(() => setSaving(false))
    // }

    const handleFormSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {        

        if (!values.arquivos || !values.tipoDocumentoId || !values.tipoDocumentoId.value) return;

        setSaving(true)

        Promise
        .all(values.arquivos?.map((file: File) => {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = () => {
                    resolve({
                        name: file.name,
                        content: reader.result?.toString().replace(/^data:(.*,)?/, ''),
                        tipoDocumentoId: values.tipoDocumentoId?.value
                    } as IFileUploadBase64Request)
                }
                reader.readAsDataURL(file);
            })
        }))
        .then(files => {
            Promise
            .all(files.map(item => {
                const requestData = new ArquivoSeguradoraRequest(item as any)
                return OcorrenciaSeguradoraService.arquivo(id, requestData)
            }))
            .then((res: FileUploadResponse[]) => {
                // const docs = res.map((item: FileUploadResponse) => {
                //     return new DocumentacaoRequest({
                //         ocorrenciaId: +id,
                //         tipoDocumentoId: values.tipoDocumentoId && +values.tipoDocumentoId.value,
                //         arquivoId: item.id
                //     });
                // })

                fetchData();
                toast.success(Messages.form.saveSuccess)
                setShowModal(false)     
            })
            .catch(err => {
                if (typeof err === 'object') {            
                    if (err.hasOwnProperty('items')) {
                        for (var key in err['items']) {   
                            let item = err['items'][key]                     
                            let msgError = key + ': ' + item[0]
                                                    
                            toast.error(msgError)
                        }
    
                        return;
                    }
                }
                
                toast.error(Messages.form.saveError)
            })
            .finally(() => setSaving(false))
        }) 
                
    }

    const handleFormSubmitDocumentoPendente = () => {    
        const docPend = !pendenciaDocumento;

        setPendenciaDocumento(docPend)

        const body = new DocumentacaoRequest ({
            documentoPendente: docPend,
            ocorrenciaId: id,
        });

        DocumentacaoService
        .documentoPendete(body)
        .then(e => console.log(e))
        .catch(e => console.log(e));

        setShowConfirmeModal(false);
    }

    return (
        <>
            <br></br>
            
            <div className='dados_ocorrencia'>Ocorrencia: {id}</div>

            <br></br>
            <Table {...tableProps} loading={loading} />
            {
                podeAlterar &&
                <Row>
                    <Col>                        
                        <Button type="button" variant="primary" className="float-right mt-3 ml-3" onClick={handleNewItem}>Adicionar Documento</Button>            
                        <Button type="button" variant="outline-primary" className="float-right mt-3" onClick={handleNewOcorrencia}>Nova ocorrência</Button>            
                    </Col>
                </Row>
            }

            <Modal
                show={showModal}
                onHide={handleCloseModal}           
                backdrop="static"
                keyboard={false}
                size="lg"
                className="lm-modal-documento" centered     
            >
                <Modal.Header>
                    <Modal.Title className="lm-modal-documento__title">                        
                        {
                            isNew ? ("Adicionar Documento") : ("Documento")
                        }
                    </Modal.Title>                    
                </Modal.Header>
                <Modal.Body>
                    
                    <Formik 
                            validationSchema={FormSchema}
                            initialValues={initialValues}
                            enableReinitialize={true}
                            onSubmit={handleFormSubmit}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <Form onSubmit={handleSubmit}>                                                                
                                <Row>
                                    <Col>
                                        <Select
                                            name="tipoDocumentoId"
                                            label="Tipo de Documento"
                                            options={SelectFormatData(tipoDocumentoList, 'id', 'nome')}
                                            value={ values.tipoDocumentoId }
                                            onChange={ (e: any) => {
                                                if (!e.value) return;
                                                setFieldValue('tipoDocumentoId', e)
                                            } }
                                            isInvalid={touched.tipoDocumentoId && !!errors.tipoDocumentoId}
                                            isDisabled={isEditing}
                                            errorMessage={errors.tipoDocumentoId}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FileInput
                                            onChange={ (files) => setFieldValue('arquivos', files) }
                                            itensEnviados={ documentoEnviadoList }                                            
                                            handleDeleteItemEnviado={ (id) => handleDeleteItemEnviado(id) }
                                            maxFiles={10}
                                        />
                                    </Col>
                                </Row>           

                                {
                                    saving && (
                                        <>
                                            <br></br>
                                            <Row>
                                                <Col>
                                                    <ProgressBar animated now={100} />
                                                </Col>
                                            </Row><br></br><br></br>
                                        </>                                        
                                    )
                                }   

                                {
                                    ((values.arquivos && values.arquivos.length>0 && podeAlterar) && !saving) &&
                                    <Button 
                                        type="submit" 
                                        variant="primary" 
                                        className="float-right ml-3"
                                        >
                                            Salvar
                                    </Button>
                                }

                                {
                                    !saving && (
                                        <Button 
                                            variant="outline-primary" 
                                            className="float-right" 
                                            onClick={handleCloseModal}
                                        >
                                            Voltar
                                        </Button>
                                    )
                                }                                                                
                                
                            </Form>
                        )}
                        </Formik>
                                                
                </Modal.Body>
                
            </Modal>

            <Modal  show={showDeleteModal} 
                    onHide={handleCloseDeleteModal} 
                    className="lm-table__modal lm-table__modal-delete" 
                    size="lg"
                    centered>
                <Modal.Header closeButton>
                    <Modal.Title>{"Documento"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{"Todos os arquivos desse tipo de documento serão excluídos, confirma exlusão?"}</Modal.Body>
                <Modal.Footer>
                    <Button block size="lg" variant="outline-primary" onClick={handleCloseDeleteModal}>
                        {Messages.inputtag.modal.back}
                    </Button>
                    <Button block size="lg" variant="danger" onClick={() => handleConfirmDeleteModal()}>
                        {Messages.inputtag.modal.confirm}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal  show={showDeleteItemEnviadoModal} 
                    onHide={handleCloseDeleteItemEnviadoModal} 
                    className="lm-table__modal lm-table__modal-delete" 
                    size="lg"
                    centered>
                <Modal.Header closeButton>
                    <Modal.Title>{"Documento"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{"Desseja excluir o documento?"}</Modal.Body>
                <Modal.Footer>
                    <Button block size="lg" variant="outline-primary" onClick={handleCloseDeleteItemEnviadoModal}>
                        {Messages.inputtag.modal.back}
                    </Button>
                    <Button block size="lg" variant="danger" onClick={() => handleConfirmDeleteItemEnviadoModal()}>
                        {Messages.inputtag.modal.confirm}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmeModal} onHide={handleCloseConfirmeModal} className="lm-table__modal lm-table__modal-delete" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{"Documento Pendente"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        "Deseja alterar o status do Documento Pendente?"
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button block size="lg" variant="outline-danger" onClick={() => setShowConfirmeModal(false)}>
                    {Messages.inputtag.modal.back}
                        
                    </Button>
                    <Button block size="lg" variant="primary" onClick={() => handleFormSubmitDocumentoPendente()}>
                        {Messages.inputtag.modal.confirm}
                    </Button>
                </Modal.Footer>
            </Modal>

            <FileGallery
                files={viewFiles.files}
                mode="fullscreen"
                show={viewFiles.show}
                onShow={handleShowFileGallery}
            />
        </>
    )

}

export default DocumentoTab