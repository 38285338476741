import React from 'react'
import Button, { ButtonProps } from 'react-bootstrap/Button'
import './style.scss'

const LMButton: React.FC<ButtonProps> = (props) => {
    return (
        <Button variant="outline-primary" {...props}>
            { props.children }
        </Button>
    )
}

export default LMButton