import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Input, Alert, Form } from '../../components'
import Logo from './img/logo.svg'
import Banner from './img/banner.png'
import { AuthProvider } from '../../providers'
import Messages from '../../config/messages'
import './style.scss'

interface LoginType {
    username: string;
    password: string;
}

const FormSchema = Yup.object().shape({
    username: Yup.string().required(Messages.form.required),
    password: Yup.string().required(Messages.form.required),
})

const LoginScreen = () => {

    const [initialValues] = useState<LoginType>({
        username: '',
        password: '',
    })

    const [successMessage, setSuccessMessage] = useState<string | undefined>()
    const [loadingMessage, setLoadingMessage] = useState<string | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    
    const { signin } = AuthProvider.useAuth();

    const handleFormSubmit = (data: LoginType, { setSubmitting }: FormikHelpers<LoginType>) => { 

        setSuccessMessage('')
        setErrorMessage('')
        setLoadingMessage('Aguarde, estamos processando a autenticação.')
        
        signin(data.username, data.password)
        .then((res: any) => {
            setSuccessMessage('Informações salvas com sucesso.')
            setErrorMessage('')
            setLoadingMessage('')
        })
        .catch(() => {
            setSuccessMessage('')
            setLoadingMessage('')
            setErrorMessage('Não conseguimos completar sua solicitação.')
        })
    }

    return (
        <Container fluid>
            <Row style={{ 'justifyContent': 'center' }}>
                {/* <Col className="login-screen__banner">
                    <div className="login-screen__banner__logo">
                        <img src={Logo} alt="Logo"/>
                    </div>
                    <div className="login-screen__banner__text">
                        Mobilidade para empresas e pessoas.
                    </div>
                    <img src={Banner} className="login-screen__banner__image" alt="Banner" />
                </Col> */}
                <Col className="login-screen__content">
                    <Formik 
                        validationSchema={FormSchema}
                        initialValues={initialValues}
                        enableReinitialize={true}
                        onSubmit={handleFormSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <Form onSubmit={handleSubmit} className="mt-4">
                                {/* <div className="login-screen__content__header">
                                    Esqueceu a senha? <Link to="/recuperar-senha">Recuperar</Link>
                                </div> */}
                                <Row className="justify-content-center mb-3">
                                    <Col md={7}>
                                        <h2 className="login-screen__title">
                                            Formulário Seguradora
                                        </h2>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center mb-2">
                                    <Col md={7}>
                                        <Input 
                                            name="username" 
                                            label="E-mail" 
                                            value={values.username} 
                                            onChange={ handleChange } 
                                            isInvalid={touched.username && !!errors.username} 
                                            errorMessage={errors.username}
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify-content-center mb-3">
                                    <Col md={7}>
                                        <Input 
                                            type="password"
                                            name="password" 
                                            label="Senha" 
                                            value={values.password} 
                                            onChange={ handleChange } 
                                            isInvalid={touched.password && !!errors.password} 
                                            errorMessage={errors.password}
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify-content-center mb-4">
                                    <Col md={7}>
                                        <Button 
                                            size="lg" 
                                            block
                                            type="submit"
                                            disabled={ !!loadingMessage }
                                        >
                                            { !!loadingMessage ? "Autenticando..." : "Entrar"}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={7}>
                                        { loadingMessage && (
                                            <Alert className="mt-4" variant="info">
                                                { loadingMessage }
                                            </Alert>
                                        )}
                                        { successMessage && (
                                            <Alert className="mt-4" variant="success">
                                                { successMessage }
                                            </Alert>
                                        )}
                                        { errorMessage && (
                                            <Alert className="mt-4" variant="danger">
                                                { errorMessage }
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginScreen