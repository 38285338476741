import { Formik, FormikHelpers } from "formik";
import * as Yup from 'yup'
import { Button, Form, Modal } from '../../components'
import { Col, Row } from "react-bootstrap";
import OcorrenciaAcidente from "./ocorrenciaAcidente";
import { useRef, useState } from "react";
import CadastroBasico from "./cadastroBasico";
import messages from "../../config/messages";
import Config from "../../config/config";
import { 
    OcorrenciaSeguradoraClient, 
    OcorrenciaSeguradoraRequest, 
    OcorrenciaAcidenteVitimaRequest 
} from "../../services/externo";
import { AuthProvider } from "../../providers";
import { toast } from "react-toastify";
import Utils from '../../helpers/utils'
import { useHistory } from 'react-router-dom'
import './style.scss'

interface Props {
    changeTab(eventKey: string): void
}

const CadastroBasicoFormScreen = ({ changeTab }: Props) => {
    const childRef = useRef();
    const { user } = AuthProvider.useAuth();
    const history = useHistory();

    const [initialValues, setInitialValues] = useState({
        estado: "",
        cidade: "",
        cidadeId: "",
        placaLM: "",
        nomeTerceiro: "",
        telefoneTerceiro: "",
        emailTerceiro: "",
        placaTerceiro: "",
        dataAcidente: "",
        descricaoSinistro: "",
        possuiSeguro: false,
        nomeSeguradora: "",
        numProtocoloSeguradora: ""
    })

    const OcorrenciaSeguradoraService = new OcorrenciaSeguradoraClient(Config.API_BASE_URL).setBearer(String(user?.token));

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);    

    const [idOcorrencia, setIdOcorrencia] = useState<number>();

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false)
    }

    const handleConfirmFazerUploadModal = () => {                    

        changeTab('documento')

        handleCloseSuccessModal()

    }

    const handleNovaOcorrenciaModal = () => {
        history.push(`/formulario-seguradora/add`)
    }

    const FormSchema = Yup.object().shape({
        estado: Yup.object()
            .nullable()
            .required(messages.form.required),
        cidade: Yup.object()
            .nullable()
            .required(messages.form.required),
        placaLM: Yup.string().required(messages.form.required).max(10, "Este campo só aceita no máximo 10 caracteres."),
        nomeTerceiro: Yup.string().required(messages.form.required).max(250, messages.form.maxLength250),
        telefoneTerceiro: Yup.string().required(messages.form.required).test('telefone-obrigatorio', messages.form.required, (value) => !!value && Utils.removeSpecialCharacters(value).length >= 10),
        emailTerceiro: Yup.string().required(messages.form.required).max(250, messages.form.maxLength250),
        placaTerceiro: Yup.string().required(messages.form.required).max(10, "Este campo só aceita no máximo 10 caracteres."),
        dataAcidente: Yup.string().required(messages.form.required),
        descricaoSinistro: Yup.string().required(messages.form.required).max(2400, "Este campo só aceita no máximo 2400 caracteres."),
        possuiSeguro: Yup.boolean().required(messages.form.required),
        nomeSeguradora: Yup.string().max(250, messages.form.maxLength250).notRequired(),
        numProtocoloSeguradora: Yup.string().max(250, messages.form.maxLength250).notRequired() 
    });

    const handleFormSubmit = async (values: any, { setSubmitting }: FormikHelpers<any>) => {

        let numTelefone = (values?.telefoneTerceiro ?? "").replace(/[^\d]+/g, '');

        let modelAcidente = (childRef.current ?? {} as any).getModelAcidente();        

        const body = {
            "cidadeId": values.cidadeId,
            "cidade": values.cidade.label,
            "estadoSigla": values.estado.label,
            "veiculoPlaca": values.placaLM,
            "dataAcidente": values.dataAcidente,
            "descricao": values.descricaoSinistro,
            "terceiro": {
                "nome": values.nomeTerceiro,
                "telefoneDdd": numTelefone ? numTelefone.substring(0,2) : "",
                "telefone": numTelefone ? numTelefone.substring(2) : "",
                "email": values.emailTerceiro,  
                "veiculoPlaca": values.placaTerceiro,
                "possuiSeguro": values.possuiSeguro,
                "nomeSeguradora": values.nomeSeguradora,
                "numProtocoloSeguradora": values.numProtocoloSeguradora,
            },
            "vitima": modelAcidente?.itemsVitima.map((e: any) => 
                    new OcorrenciaAcidenteVitimaRequest({
                        vitimaId: e.id ?? 0,
                        nomeVitima: e.nomeVitima ?? "",
                        situacaoVitima: e.situacaoVitima ?? "",
                        numDdd: e.numDdd ?? "",
                        numTelefone: e.numTelefone ?? "",
                    })
                )
          } as OcorrenciaSeguradoraRequest

        
            OcorrenciaSeguradoraService
            .ocorrenciaSeguradoraPost(body)
            .then((res) => {
                                                
                setIdOcorrencia(res.id ?? 0);                                        

                history.push(`${res.id}?success=true`)    
            })
            .catch((error) => {
                toast.error(Utils.processErrorMessage(error))
            })
        
        
    }

    return (
        <>
            <Formik
                validationSchema={FormSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={handleFormSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched
                }) => (
                    <Form onSubmit={handleSubmit} className="mt-12">
                        <CadastroBasico
                            values={values}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                        />

                        <OcorrenciaAcidente 
                            idOcorrencia={idOcorrencia}
                            podeAlterar={true}
                            valuesTerceiros={values}
                            ref={childRef}                            
                        />

                        <Row>
                            <Col>
                                <Button type="submit" variant="primary" className="float-right mt-4 btn btn-primary">
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                        
                    </Form>
                )}
            </Formik>            
        </>        
    )
}

export default CadastroBasicoFormScreen