import { Card, CardProps } from 'react-bootstrap'

const LMCard = (props: CardProps) => {
    return (
        <Card {...props}>
            {props.children}
        </Card>
    )
}

export default LMCard