import { useEffect, useState } from 'react'
import Icon from '@material-ui/core/Icon';
import './style.scss'
import { setSyntheticLeadingComments } from 'typescript';

interface OptionItemColorNormal {
    color: string
}
interface OptionItemColorHighlight {
    color: string,
    backgroundColor: string
}

interface OptionItemColor {
    normal: OptionItemColorNormal,
    highlight: OptionItemColorHighlight
}

interface OptionItem {
    label: string | number,
    value: string | number,
    color?: string
}

interface OptionItemColored extends Omit<OptionItem, 'color'> {
    color:  OptionItemColor
}

interface Props {
    label: string,
    options?: OptionItem[],
    className?: string,
    onChange(option?: OptionItem): void,
    value?: string | number
}

const CustomRadio = (props: Props) => {

    const { label, value, options, className, onChange } = props;

    const [selected, setSelected] = useState<string | number>();
    const [hover, setHover] = useState<string | number>();
    
    const defaultColor = "#0076C0";

    const coloredOptions = options?.map((item) : OptionItemColored  => {
        return {
            ...item,
            color: {
                normal: {
                    color: item.color ? item.color as string : defaultColor
                },
                highlight: {
                    backgroundColor: item.color ? item.color as string : defaultColor,
                    color: "#FFF"
                }
            }
        }
    })

    return (
        <div className={ `custom-radio ${className}` }>
            <div className="custom-radio__label">
                { label }
            </div>
            <div className="custom-radio__options">
                { 
                    coloredOptions?.map((item, key: number) => (
                        <div 
                            key={key}
                            className={ `custom-radio__options__item ${ value === item.value ? 'custom-radio__options__item--active' : '' }` }
                            onClick={ 
                                () => {
                                    if (value === item.value) {
                                        onChange(undefined)
                                    } else {
                                        onChange({
                                            ...item,
                                            color: item.color.normal.color
                                        })
                                    }
                                }
                            }
                            onMouseOver={ () => setHover(item.value) }
                            onMouseOut={ () => setHover(undefined) }
                            style={
                                (value === item.value || hover === item.value) ? item.color.highlight as object : item.color.normal as object
                            }
                        >
                            <Icon>
                                { value === item.value ? 'check' : 'radio_button_unchecked'  }
                            </Icon>
                            { item.label.toString().toLowerCase() }
                        </div>
                    )) 
                }
            </div>
        </div>
    )
}

export default CustomRadio