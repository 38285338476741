import { Tabs, Tab, TabsProps, TabProps } from 'react-bootstrap'
import './style.scss'

interface TabItem extends TabProps {
    content: React.ReactNode
}

interface Props extends TabsProps {
    list: TabItem[],
    changeTab(eventKey: string): void
}

const LMTabs = (props: Props) => {

    const { list, changeTab, ...tabsProps } = props;

    const handleSelect = (eventKey: any) => {
        changeTab(eventKey)
    }

    return (
        <div className="lm-tabs">
            <Tabs
                defaultActiveKey={ list[0].eventKey }
                {...tabsProps}
                onSelect={ handleSelect }
            >
                {
                    list.map((item: TabItem, index: number) => (
                        <Tab key={index} eventKey={item.eventKey} title={ item.title } disabled={ item.disabled }>
                            { item.content }
                        </Tab>
                    ))
                }
            </Tabs>
        </div>
    )
}

export default LMTabs