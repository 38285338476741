import Icon from '@material-ui/core/Icon';
import { Title, Card, Button } from '../'
import Messages from '../../config/messages'
import './style.scss'

interface Props {
    children: React.ReactNode
    visible: boolean,
    setVisible(visible: boolean): void,
    title?: string,
    icon?: string,
}

const GridFilter = (props: Props) => {

    const { title, icon, children, visible, setVisible } = props;

    const toggleIcon = (on: boolean) => on ? 'expand_less' : 'expand_more';

    const handleToggle = () => {
        if (setVisible) {
            setVisible(!visible)
        }
    }

    return (
        <Card className={ "grid-filter " + (visible ? '' : 'grid-filter--hidden') }>
            <div className="grid-filter__header">
                <Title title={ title ? title : Messages.gridfilter.title } icon={ icon ? icon : 'search' } />
                
                <Button variant="link" className="grid-filter__header__btn" onClick={ handleToggle }>
                    <span>
                        { visible ? Messages.gridfilter.hideFields : Messages.gridfilter.showFields }
                    </span>
                    <Icon>{toggleIcon(visible)}</Icon>
                </Button>

            </div>
            <div className="grid-filter__body">
                { children }
            </div>
        </Card>
    )
}

export default GridFilter