import React, { useState } from 'react'
import { Dropdown, Button, Form } from 'react-bootstrap'
import './style.scss'

interface OptionItem {
    label: string,
    key: string | number,
    checked: boolean,
    toggleHidden(): void
}
interface DropdownCheckboxProps {
    id: string,
    label: string | number,
    options: OptionItem[]
}

const DropdownPersist = (props: any) => {
    const [open, setOpen] = useState(false);
    const onToggle = (isOpen: boolean, ev: any, metadata: any) => {
      if (metadata.source === "select") {
        setOpen(true);
        return;
      }
      setOpen(isOpen);
    };
    return <Dropdown show={open} onToggle={onToggle} {...props}></Dropdown>;
  };

const DropdownCheckbox: React.FC<DropdownCheckboxProps> = (props) => {

    const { id, label, options } = props;

    const CustomMenu = React.forwardRef<HTMLInputElement>(
        ({ children, style, className, 'aria-labelledby': labeledBy } : any, ref) => {
      
          return (
            <div
              ref={ref}
              style={style}
              className={className}
              aria-labelledby={labeledBy}
            >
                { React.Children.toArray(children) }
            </div>
          );
        },
      );

    const handleClick = (eventKey: any, event: Object) => {
        options[eventKey].toggleHidden()
    }

    return (
        <DropdownPersist className="lm-dropdown-checkbox">
            <Dropdown.Toggle as={Button} id={id}>
                {label}
            </Dropdown.Toggle>
    
            <Dropdown.Menu as={CustomMenu}>
                {
                    options.filter(item => item.label.length > 0).map((item, key) => {
                        return (
                            <Dropdown.Item key={key} eventKey={key as unknown as string} onSelect={ handleClick }>
                                <Form.Check type="checkbox" label={ item.label } defaultChecked={item.checked} />
                            </Dropdown.Item>
                        )
                    })
                }
            </Dropdown.Menu>
      </DropdownPersist>
    )
}

export default DropdownCheckbox