import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, LoadingProvider } from './providers'
import {
  BrowserRouter as Router
} from "react-router-dom";
import Config from './config/config'

const { APPINSIGHTS_INSTRUMENTATIONKEY } = Config;

const browserHistory = createBrowserHistory({ basename: '' });

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APPINSIGHTS_INSTRUMENTATIONKEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});

appInsights.loadAppInsights();

const getBasename = (path: string) => path.substr(0, path.lastIndexOf('/'));

ReactDOM.render(
  <React.StrictMode>
    <Router basename={getBasename(window.location.pathname)}>
      <LoadingProvider.Provider>
        <AuthProvider.Provider>
          <App />
        </AuthProvider.Provider>
      </LoadingProvider.Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
