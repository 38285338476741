import Icon from '@material-ui/core/Icon';
import './style.scss'

export interface TitleProps {
    title: string;
    icon?: string;
}

const Title = (props: TitleProps) => {

    const { title, icon } = props;

    return (
        <h1 className="lm-title">
            { icon && (
                <Icon>{icon}</Icon>
            )}
            { title }
        </h1>
    )
}

export default Title