import { DropdownButton, InputGroup, Dropdown, FormControl, Row, Col, Container } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { ToastContainer } from 'react-toastify'
import Icon from '@material-ui/core/Icon';
import './style.scss'

interface ContentProps {
    user?: string,
    signout(): void,
    className?: string,
    children?: React.ReactNode,
    statusBar?: React.ReactNode,
}

const Content = (props: ContentProps) => {

    const { children, statusBar, className, user, signout } = props;

    const date = DateTime.fromJSDate(new Date());

    return (
        <Container fluid className={ "lm-content " + className }>
            <Row>
                <Col className="lm-content__header">
                    <div className="lm-content__header__search" />
                    <div className="lm-content__header__session">
                        <div className="lm-content__header__session__data">
                            <div className="lm-content__header__user">
                                { user }
                            </div>
                            <div className="lm-content__header__date">{ date.setLocale('pt-BR').toLocaleString(DateTime.DATE_HUGE) }</div>
                        </div>
                        <button type="button" onClick={signout} className="btn lm-content__header__logout" title="Sair">
                            <Icon>exit_to_app</Icon> <span>Sair</span> 
                        </button>
                    </div>
                </Col>
            </Row>
            {
                statusBar && (
                    <Row>
                        <Col className="lm-content__status-bar">
                            { statusBar }
                        </Col>   
                    </Row> 
                        
            )}
            <Row style={{ 'justifyContent': 'center' }}>
                <Col className="lm-content__content">
                    { children }
                </Col>
            </Row>
            <ToastContainer 
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                limit={3}
            />
        </Container>
    )

}

export default Content