import { useState, useEffect } from 'react'
import { Checkbox, Input } from '../'
import { CheckboxProps } from '../Checkbox'
import './style.scss'

interface Props extends Omit<CheckboxProps, 'onChange'> {
    name: string,
    onChange(value?: string | null): void,
    inputSuffix?: string
}

const CheckInput = (props: Props) => {

    const { inputSuffix, onChange } = props;
    const [value, setValue] = useState<string>();
    const [checkboxValue, setCheckboxValue] = useState<boolean>();
    const [inputValue, setInputValue] = useState<string>();

    useEffect(() => {
        onChange(value)
    }, [value])

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.checked ? inputValue : '')
        setCheckboxValue(event.target.checked)
        setValue(event.target.checked ? inputValue : '')
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value !== '' ? event.target.value : '')
        setCheckboxValue(event.target.value !== '' ? true : false)
        setValue(event.target.value !== '' ? event.target.value : '')
    }
    
    return (
        <div className="lm-check-input" >
            <Checkbox 
                {...props}
                checked={checkboxValue}
                onChange={handleCheckboxChange}
            />
            <Input
                name={ props.name }
                value={inputValue}
                onChange={handleInputChange}
            />
            { inputSuffix && (
            <div className="lm-check-input__suffix">
                { inputSuffix }
            </div>
            )}
        </div>
    )
}


export default CheckInput