declare var window: SoftWindow;

const Config = {
    "APP_NAME": window.__env__ && window.__env__.SOFT_NAME ? window.__env__.SOFT_NAME : "Seguradora",
    "APP_GUID": window.__env__.SOFT_APP_GUID ? window.__env__.SOFT_APP_GUID : "0517EDC0-29E0-4E12-B137-52A0EAE28FBF",
    "APP_PRIVATE_GUID": window.__env__.SOFT_APP_PRIVATE_GUID ? window.__env__.SOFT_APP_PRIVATE_GUID : "DA97E493-44FD-46BF-A70B-863B818D2D33",
    "API_BASE_URL": window.__env__.SOFT_APP_API_URL ? window.__env__.SOFT_APP_API_URL : "http://devsoft-api.lmfrotas.com.br",
    "APPINSIGHTS_INSTRUMENTATIONKEY": window.__env__.APPINSIGHTS_INSTRUMENTATIONKEY ? window.__env__.APPINSIGHTS_INSTRUMENTATIONKEY : "597df095-67aa-4bf4-a253-d0fa811828a0"
}

export default Config