import Messages from '../config/messages'
import Config from '../config/config'

const dateToString = (date: any) => {
    return String(date.getDate()).padStart(2, '0') 
    + '/' + String(date.getMonth() + 1).padStart(2, '0') 
    + '/' + date.getFullYear() + " " 
    + String(date.getHours()).padStart(2, '0') + ":" 
    + String(date.getMinutes()).padStart(2, '0') 
}

const dateWithoutTimeToString = (date: any) => {
    return String(date.getDate()).padStart(2, '0') 
    + '/' + String(date.getMonth() + 1).padStart(2, '0') 
    + '/' + date.getFullYear() 
}

const removeSpecialCharacters = (value: string) => {
    return value.replace(/[^a-zA-Z0-9 ]/g, "");
}

const formatMoney = (value: number) => {
    return "R$ " + value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
}

const formatCpf = (value: string) => {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, "$1.$2.$3-$4");
}

const processErrorMessage = (err: any) => {
    if (typeof err === 'object') {            
        if (err.hasOwnProperty('items')) {
            
            for (var key in err['items']) {   
                let item = err['items'][key]                     
                let msgError = key + ': ' + item[0]
                                        
                return msgError;
            }            
        }
    }

    return Messages.form.saveError;
}

const humanFileSize = (bytes: number, si: boolean = true, dp: number = 1) => {
    const thresh = si ? 1000 : 1024;
    
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    
    const units = si 
        ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
    
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    
    
    return bytes.toFixed(dp) + ' ' + units[u];
}

export const formatFilesViewed = (list: any) => {
    const files =  list.map((item: any) => ({
        original: `${Config.API_BASE_URL}/api/storage/v1/FileDownload/${item.arquivo.guidPublica}${item.arquivo.extensao}`,
        thumbnail: `${Config.API_BASE_URL}/api/storage/v1/FileDownload/${item.arquivo.guidPublica}${item.arquivo.extensao}`,
        originalTitle: item.arquivo.nomeArquivo
    }))

    return files
}

export default { humanFileSize, dateToString, dateWithoutTimeToString, removeSpecialCharacters, formatMoney, formatCpf, processErrorMessage }


