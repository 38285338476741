import DatePicker, { ReactDatePickerProps } from "react-datepicker"
import Icon from '@material-ui/core/Icon'
import { Form } from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';

import "react-datepicker/dist/react-datepicker.css"
import './style.scss'

registerLocale('pt-BR', ptBR)

interface Props extends ReactDatePickerProps {
    label: string,
    icon?: string
    errorMessage?: string,
    isInvalid?: boolean,
    size?: "sm" | "lg",
    mode?: "datetime" | "date" | "time"
}

const LMDatePicker = (props: Props) => {
    
    const { name, label, icon, size = "sm", isInvalid, errorMessage, mode = "date", ...inputProps } = props

    let dateFormat

    if (mode === "datetime") {
        dateFormat = "dd/MM/yyyy HH:mm:ss"
    } else if (mode === "date") {
        dateFormat = "dd/MM/yyyy"
    } else {
        dateFormat = "HH:mm:ss"
    }

    return (
        <Form.Group controlId={ name } className={ `lm-form-input lm-datepicker ${ icon ? 'lm-datepicker--icon' : '' }` }>
            <Form.Label>{ label }</Form.Label>
            { icon && (
                <Icon className="lm-datepicker__icon">{icon}</Icon>
            )}
            <DatePicker 
                name={name}
                wrapperClassName={ "react-datepicker-wrapper" + ( isInvalid ? " is-invalid" : '' )}
                className={ "form-control" + ( size === "lg" ? " form-control-lg" : '' ) + ( isInvalid ? " is-invalid" : '' ) }
                dateFormat={ dateFormat }
                timeFormat={ "HH:mm" }
                showTimeSelect={ mode !== "date" ? true : false }
                showTimeSelectOnly={ mode === "time" ? true : false }
                locale="pt-BR"
                timeCaption={ "Hora" }
                customInput={
                    <MaskedInput 
                        mask={mode === "datetime" ? "11/11/1111 11:11" : "11/11/1111"}/>
                }
                { ...inputProps }
            />
            { errorMessage && (
            <Form.Control.Feedback type="invalid">
                  {errorMessage}
            </Form.Control.Feedback>
            )}
        </Form.Group>
    )

}

export default LMDatePicker