import React, { useState } from 'react'
import Dropzone, { DropzoneProps } from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Button } from '..'
import Utils, { formatFilesViewed } from '../../helpers/utils'
import './style.scss'
import Config from '../../config/config'
import FileGallery from '../FileGallery';

interface FileViewed {
    original: string
    thumbnail: string
    originalTitle: string
}
interface ViewFiles {
    show: boolean
    files: FileViewed[] | [],
    startIndex: number
}
interface Props extends DropzoneProps {
	onChange(acceptedFiles: any[]): void,
	itensEnviados?: any[],		
	handleDeleteItemEnviado?(documentoId: number): void,
}

const FileInput = (props: Props) => {

	const { 
		onChange, 
		itensEnviados,
		handleDeleteItemEnviado,
		maxSize = 26214400,
		...dropzoneProps 
	} = props;

	const { API_BASE_URL } = Config;

	const [files, setFiles] = useState<any>([]);
	const [viewFiles, setViewFiles] = useState<ViewFiles>({show: false, files: [], startIndex: 0})

	const handleChange = (acceptedFiles: any[]) => {
		const newFiles = [
			...files,
			...acceptedFiles
		]

		setFiles(newFiles)
		onChange(newFiles)
	}

	const handleItemDelete = (index: number) => {
		const filesCopy = [...files];
		filesCopy.splice(index, 1);
		setFiles(filesCopy)
		onChange(filesCopy)
	}

	const handleViewFile = (guid: any, extension: string) => {		

		if(canViewFile(extension)){
			let fileWindow = window.open("")				

			let url = `${API_BASE_URL}/api/storage/v1/FileDownload/${guid}${extension}`

			if(fileWindow){
				fileWindow.document.write(`<iframe style='border: 0;' width='100%' height='100%' src='${url}'></iframe>`)
			}

			// fileDownloadService
			// .base64Get(guid)
			// .then((res) => {            
								
			// 	let url = `data:${res.contentType ?? ''};base64, ${encodeURI(res.content ?? '')}`				

			// 	if(fileWindow){
			// 		fileWindow.document.write(`<iframe style='border: 0;' width='100%' height='100%' src='${url}'></iframe>`)
			// 	}
				
			// })
			// .catch(err => {
			// 	console.log(err)
			// })				
		}
		else{
			downloadFile(guid, extension)
		}					
	}

	const handleOpenFileGallery = (startIndex: number) => {
		setViewFiles({show: true, files: formatFilesViewed(itensEnviados), startIndex })
	}

	const handleShowFileGallery = (isFullscreen: boolean) => {
		setViewFiles({...viewFiles, show: isFullscreen})
	}

	const downloadFile = (guid: any, extension: string) => {		

		let url = `${API_BASE_URL}/api/storage/v1/FileDownload/${guid}${extension}`

		let hiddenIFrameID = 'hiddenDownloader'
		let iframe = document.getElementById(hiddenIFrameID);

		if (iframe === null) {
			iframe = document.createElement('iframe');
			iframe.id = hiddenIFrameID;
			iframe.style.display = 'none';
			document.body.appendChild(iframe);
		}
		iframe.setAttribute('src', url);
				
	}

	const canViewExtensions = ['.png', '.bmp', '.gif', '.jpg', '.jpeg', '.pdf']

	const canViewFile = (extension: string) => {		
		const indexProc = canViewExtensions.findIndex(e => e === extension.toLowerCase());  

		if(indexProc>-1) 
			return true

		return false
	}

	const errorMessage = new Map([
		['file-invalid-type', "Tipo de arquivo não permitido."],
		['file-too-large', "Tamanho do arquivo é maior que o permitido."],
		['file-too-small', "Tamanho do arquivo é menor que o permitido."],
		['too-many-files', "Quatidade de arquivos é maior que o permitido."]
	]);

	return (
		<div className="lm-file">
			<Dropzone {...dropzoneProps} maxSize={maxSize} onDrop={ handleChange }>
				{({fileRejections, getRootProps, getInputProps}) => (
					<>
						<div className="lm-file__input">
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<span className="material-icons MuiIcon-root lm-file__input__icon" aria-hidden="true">upload</span>
								<div className="lm-file__input__label">
									Solte os arquivos aqui ou faça o <span>Upload</span>
								</div>
								<div className="lm-file__input__tip">
									Tamanho máximo do arquivo: { Utils.humanFileSize(maxSize) }
								</div>
							</div>
						</div>
						{ fileRejections.length > 0 && (
						<div className="lm-file__errors">
							<div className="lm-file__errors__label">Erros</div>
							{
								fileRejections.map((item: any, index: number) => (
									item.errors.map((e: any) => (
										<li className="lm-file__errors__item" key={index}>
											<div className="lm-file__errors__item__label">
												{item.file.path}
											</div>
											<div key={e.code} className="lm-file__errors__item__error">{errorMessage.get(e.code)}</div>
										</li>
									))
								))
							}
						</div>
						)}
					</>
				)}
			</Dropzone>

			{ files.length > 0 && (
				<div className="lm-file__files">
					
					<div className="lm-file__files__label">Arquivos novos:</div>

					<div className="lm-file__files__list">
						{ files.map((item: File, index: number) => (
							<div className="lm-file__files__list__item" key={index}>
								<AttachFileIcon className="lm-file__files__list__item__icon" />
								<div className="lm-file__files__list__item__name">
									{ item.name }
								</div>
								<div className="lm-file__files__list__item__size">
									{ Utils.humanFileSize(item.size) }
								</div>
								<div className="lm-file__files__list__item__actions">
									<Button 
										onClick={ () => handleItemDelete(index) } 
										size="sm"
										variant="danger"
										className="lm-file__files__list__item__actions__delete"
									>
											<CloseIcon />
									</Button>
								</div>
							</div>
						)) }
					</div>
				</div>
			)}

			{ (itensEnviados && itensEnviados.length > 0) && (
				<div className="lm-file__files">
					
					<div className="lm-file__files__label">Arquivos Enviados:</div>

					<div className="lm-file__files__list">
						{ itensEnviados.map((item: any, index: number) => (
							<div className="lm-file__files__list__item" key={index}>
								<span style={{ cursor : "pointer" }} onClick={() => { handleViewFile(item.arquivo.guidPublica, item.arquivo.extensao) }}>
									<AttachFileIcon className="lm-file__files__list__item__icon" />
								</span>								

								<div style={{ cursor : "pointer" }} className="lm-file__files__list__item__name" onClick={ () => { handleViewFile(item.arquivo.guidPublica, item.arquivo.extensao) }}>
									{ item.arquivo.nomeArquivo }
								</div>

								{/* <div className="lm-file__files__list__item__size">
									{ Utils.humanFileSize(item.size) }
								</div> */}

								<div className="lm-file__files__list__item__actions">
									<Button 
										onClick={() => handleOpenFileGallery(index)} 
										size="sm"
										variant="primary"
										className="lm-file__files__list__item__actions__delete"
									>
										<VisibilityIcon />
									</Button>

									{
										handleDeleteItemEnviado && (
											<Button 
												onClick={ () => handleDeleteItemEnviado(item.documentoId ?? 0) } 
												size="sm"
												variant="danger"
												className="lm-file__files__list__item__actions__delete"
											>
													<DeleteIcon />
											</Button>
										)
									}
								</div>

								
								
							</div>
						)) }
					</div>
				</div>
			)}

			<FileGallery
                files={viewFiles.files}
                mode="fullscreen"
                show={viewFiles.show}
                onShow={handleShowFileGallery}
				startIndex={viewFiles.startIndex}
            />
		</div>
    )
};

export default FileInput;