export class apiBase {
    bearer = '';

    setBearer(bearer: string) {
        this.bearer = bearer;

        return this;
    }

    protected transformOptions(options: any): Promise<any> {
        if(this.bearer !== '') {
            options.headers["Authorization"] = `Bearer ${this.bearer}`;
        }

        return Promise.resolve(options);
    }
}