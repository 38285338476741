import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Logo from './img/logo.svg'
import './style.scss'

const LoadingScreen = () => {
    
    return (
        <Container fluid className="loading-screen">
            <Row>
                <img src={Logo} alt="Logo" className="loading-screen__logo" />
                <div className="loading-screen__text">
                    Carregando...
                </div>
            </Row>
        </Container>
    )
}

export default LoadingScreen